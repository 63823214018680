import React from 'react'
import landing3 from './images/landing3.png'
import landing4 from './images/landing4.png'

import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

class About extends React.Component{
    render(){
        return(
            <div style = {{backgroundImage : `url(${landing4})`, width: '100%', minHeight: '750px', height: 'fit-content'}}>
            <div className = 'pt-5 text-right ' style= {{color: 'white', direction: 'rtl'}}>
                
                
                <div className ='pr-md-5 mr-md-5' >
               <div className='text-left'>
               <img alt = 'img'src = {landing3} width='300'/>
               </div>
                <div className=' ml-4'>
                <p  style= {{fontSize: '38px'}}>
                الأهداف
                </p>

                <Zoom >
<hr className = 'mr-2' style = {{width: '150px', borderTop: '1px solid white'}}/>

                </Zoom>
                <p style ={{fontSize: '20px', color : 'lightGray'}}>
                صممنا تطبيق Tone ليكون رفيقاً لكم في جميع الفعاليات الاجتماعية المتنوعة مثل الحفلات الموسيقية والمباريات وغيرها، 
                <br/>
                ويهدف بشكل أساسي إلى تحقيق الفائدة لكل من المستخدم ومقدم الفعالية وفق الآتي:
                </p>
                </div>

                
                </div>
                <div className ='mr-md-5 mt-5'>
                  <Fade delay={100}>

                  <div className = 'mr-5'>
                           <p style ={{fontSize: '20px', color : 'lightGray'}}>
                        	تسهيل إعداد الفعاليات والعمل عليها والاستعداد لها على أصحاب الفعاليات
                        <br/>
                        ، وخلق بيئة متكاملة لإدارة مختلف الفعاليات بشكل منظم ومريح.
                        </p>
                    </div>

                  </Fade>

                   <Fade delay={200}>
                   <div className = 'mr-5'>
                      
                        <p style ={{fontSize: '20px', color : 'lightGray'}}>
                        	مساعدة الجمهور على البحث عن الفعاليات التي تثير اهتمامهم وحجز التذاكر الإلكترونية لها بطريقة سريعة وبسيطة، 
                        <br/>

                        وتوفير الوقت الضائع في الانتظار واجتناب الزحمة.
                        </p>
                    </div>
                   </Fade>

               
                </div>

                </div>
          
        </div>
        )
    }
}

export default About