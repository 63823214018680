import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBan, faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Translate from 'react-translate-component';
import store from '../store';
import EditEventModal from './editEventModal';

class AttendPage extends React.Component {
  state = {
    eventActive: false,
    editEventModal: false,
  };
  handleEditEventModal = () => {
    this.props.clearEvent();
    this.setState({ editEventModal: false });
  };
  getBookedSeatsNames = (bookedSeats) => {
    if (bookedSeats && bookedSeats.length > 0) {
      let seatNames = [];
      for (let i = 0; i < bookedSeats.length; i++) {
        if (bookedSeats[i].seats.length > 0) {
          let seatsNames = bookedSeats[i].seats.map((seat) => `Seat ${seat.number} `);
          seatNames.push(seatsNames);
        }
        if (bookedSeats[i].topTables.length > 0) {
          let tobTables = bookedSeats[i].topTables.map((seat) => `Table ${seat.number} `);
          seatNames.push(tobTables);
        }
        if (bookedSeats[i].bottomTables.length > 0) {
          let bottomTables = bookedSeats[i].bottomTables.map((seat) => `Table ${seat.number} `);
          seatNames.push(bottomTables);
        }
        if (bookedSeats[i].leftTables.length > 0) {
          let rightTables = bookedSeats[i].leftTables.map((seat) => `Table ${seat.number} `);
          seatNames.push(rightTables);
        }
        if (bookedSeats[i].rightTables.length > 0) {
          let leftTables = bookedSeats[i].rightTables.map((seat) => `Table ${seat.number} `);
          seatNames.push(leftTables);
        }

      }
      return seatNames;
    }
    else {
      return null;
    }
  };
  render() {
    let { attendTickets } = this.props;
    console.log('attendTickets;', attendTickets);
    return (
      <div className="reports justify-content-center mx-auto text-center " style={{ width: '90%' }}>
        <div>
          <button
            onClick={this.props.handleBooking}
            className="btn btn-light "
            style={{ color: 'purple', background: '#dee2e6' }}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px', marginRight: '10px' }} />
            <Translate content="back" />
          </button>
        </div>
        <h5 className="my-4" style={{ color: 'purple' }}>
          <Translate content="tickets_info" />
        </h5>
        <div
          className="card shadow mx-auto text-center "
          style={{ maxWidth: '90%', width: 'fit-content', height: '400px', color: 'purple', overflow: 'auto' }}>
          <table className="mt-3">
            <thead className="mx-auto " style={{ borderBottom: '.5px solid #ff5e00 ' }}>
              <tr>
                <th>
                  <Translate content="charge_id" />
                </th>
                {/* <th>
                  <Translate content="fees" />
                </th>
                <th>
                  <Translate content="numberOfSeats" />
                </th> */}
                <th>
                  <Translate content="paidAmount" />
                </th>
                {/* <th>
                  <Translate content="price" />
                </th> */}
                <th>
                  <Translate content="scaned" />
                </th>
                {/* <th>
                  <Translate content="seatType" />
                </th> */}
              </tr>
            </thead>
            <tbody>
              {attendTickets.map((ticket, key = 0) => (
                <tr key={key++} style={{ borderBottom: '1px solid #ffa50040' }}>
                  <td>{ticket.numberOfSeats && ticket.numberOfSeats > 0 ? ticket.numberOfSeats : (this.getBookedSeatsNames(ticket.booked_seats) != null ? this.getBookedSeatsNames(ticket.booked_seats).join(',') : ticket._id)}</td>
                  {/* <td>{ticket.fees}</td> */}
                  {/* <td>{ticket.numberOfSeats}</td> */}
                  <td>{ticket.paidAmount}</td>
                  {/* <td>{ticket.price}</td> */}
                  <td>{ticket.scaned ? 'نعم' : 'لا'}</td>
                  {/* <td>{ticket.seatType}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  events: state.eventState.providerEvents,
  attendTickets: state.eventState.attendTickets,
});

export default connect(mapState, {})(AttendPage);
