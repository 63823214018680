import React from "react";
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import Map from "./googlemap";
import { connect } from "react-redux";
import { postEvent } from "../actions/eventsActions";
import axios from "axios";
import Translate from "react-translate-component";
import store from "../store";
import PreviewMultipleImages from "./multi-imageInput/PreviewMultipleImages";
import { Button } from "react-bootstrap";
import NewEventPage from "./NewEventPage";
const BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

class eventModal extends React.Component {
  state = {
    show: false,
    type: "sport",
    seatsLabel: [{}],
    seats: [],
    imageList: [],
    recurring: false,
    timeSlots: [],
  };

  getFiles = (files) => {
    this.setState({ imageList: files });
  };

  addMore = (e) => {
    this.setState({
      seatsLabel: [...this.state.seatsLabel, {}],
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onClickType = (e) => {
    this.setState({
      type: e.target.innerText,
    });
  };

  getLocation = (address, latLng) => {
    this.setState({
      address,
      latLng,
    });
  };

  onDeleteSeat = (index, e) => {
    this.setState({
      seatsLabel: this.state.seatsLabel.filter(
        (seat, number) => number !== index
      ),
    });
  };

  onClickSeatType = (index, e) => {
    let seats = this.state.seatsLabel;
    seats[index].name = e.target.innerText;

    this.setState({
      seatsLabel: seats,
    });
  };

  onChangeSeatPrice = (index, e) => {
    let seats = this.state.seatsLabel;
    seats[index].price = e.target.value;

    this.setState({
      seatsLabel: seats,
    });
  };

  onChangeSeatCount = (index, e) => {
    let seats = this.state.seatsLabel;
    seats[index].count = e.target.value;

    this.setState({
      seatsLabel: seats,
    });
  };

  onSubmit = async (e) => {
    //get the state from context (props)
    const {
      rows,
      columns,
      types,
      seats,
      topTables,
      bottomTables,
      rightTables,
      leftTables,
    } = this.props.state;

    const contextState = {
      rows,
      columns,
      types,
      seats,
      topTables,
      bottomTables,
      rightTables,
      leftTables,
    };

    console.log("state : ", contextState);

    this.setState({ errMsg: false, msg: false, loading: true });
    let user = this.props.user;

    let {
      name,
      starName,
      description,
      type,
      timeSlots,
      numberOfSeats,
      address,
      latLng,
      imageList,
      startDate,
      endDate,
      recurring,
    } = this.state;

    if (!imageList.length === 0) {
      this.setState({
        errMsg: "please upload file",
        loading: false,
      });
    } else if (imageList.length > 10) {
      this.setState({
        errMsg: "Maximum 10 images are allowed",
        loading: false,
      });
    } else if (
      !name ||
      !starName ||
      !description ||
      !type ||
      !startDate ||
      // !numberOfSeats ||
      // !address ||
      // !latLng ||
      !timeSlots.length
    ) {
      this.setState({
        errMsg: "please enter all fields",
        loading: false,
      });
    } else {
      //upload file
      try {
        const UrlArray = [];
        for (let i = 0; i < this.state.imageList.length; i++) {
          const formData1 = new FormData();
          formData1.append("file", this.state.imageList[i]);
          const res = await axios.post(BASE_SERVER + "/upload/", formData1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          UrlArray.push(res.data.imageUrl);
        }

        let body = {
          name,
          starName,
          category: type,
          description,
          startDate,
          endDate,
          recurring,
          // numberOfSeats,
          // seatsTypes: this.state.seatsLabel,
          venueMap: contextState,
          address,
          // lat: latLng.lat,
          // lng: latLng.lng,
          timeSlots,
          imageUrl: UrlArray,
          numberOfAvailableSeats: numberOfSeats,
          currency: "SAR",
          provider_id: user?._id || store.getState().auth?.userId,
          provider_name:
            JSON.parse(store.getState().auth.userData).name || "admin",
        };

        // this.props.postEvent(body);
        const res = await axios.post(BASE_SERVER + "/event/createEvent", body);
        const data = res.data;
        console.log("data : ", data);
        this.setState({
          msg: "event added successfully",
          loading: false,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    const onChangeCustom = (e) => {
      const element = document.getElementById("customFile");
      imageIsLoaded1(element.files[0]);
    };

    const imageIsLoaded1 = (file) => {
      this.setState({
        imageFile: file,
      });
    };

    // let categories = this.props.categories;
    let categories = ["sports", "education", "technology"];
    let seats = this.props.seatsTypes;
    const addTimeSlot = () => {
      this.setState({
        timeSlots: [
          ...this.state.timeSlots,
          { startTime: this.state.startTime, endTime: this.state.endTime },
        ],
        startTime: "",
        endTime: "",
      });
    };
    return (
      <div>
        <Modal
          show={this.props.eventModal}
          onHide={this.props.handleEventModal}
          style={{ color: "purple" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate content="add_new_event" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div>
              <Translate
                component="input"
                attributes={{ placeholder: "event_name" }}
                onChange={this.onChange}
                name="name"
                type="text"
              />
            </div>
            <div>
              <Translate
                component="input"
                attributes={{ placeholder: "star_name" }}
                onChange={this.onChange}
                name="starName"
                type="text"
              />
            </div>
            <div>
              <Translate
                component="input"
                attributes={{ placeholder: "ticket_name" }}
                onChange={this.onChange}
                name="ticketName"
                type="text"
              />
            </div>

            <div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic2">
                  <Translate content="event_type" />({this.state.type})
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {categories.map((type, key = 0) => (
                    <Dropdown.Item onClick={this.onClickType} key={key++}>
                      {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <hr className="bg-light mx-3"></hr>
            <div className="recurring-checkbox ">
              <span>Recurring Event</span>
              <input
                id="rec"
                type="checkbox"
                onChange={() =>
                  this.setState({ recurring: !this.state.recurring })
                }
                value={this.state.recurring}
                name="recurring"
                style={{ marginLeft: "10px", height: "20px", width: "20px" }}
              />
            </div>

            {this.state.recurring ? (
              <>
                <div>
                  <div>
                    <label htmlFor="start-date">Start Date</label>
                  </div>
                  <input
                    id="start-date"
                    type="date"
                    onChange={this.onChange}
                    name="startDate"
                  />
                </div>
                <div>
                  <div>
                    <label htmlFor="end-date">End Date</label>
                  </div>
                  <input
                    id="end-date"
                    type="date"
                    onChange={this.onChange}
                    name="endDate"
                  />
                </div>
              </>
            ) : (
              <div>
                <input type="date" onChange={this.onChange} name="startDate" />
              </div>
            )}
            <hr className="bg-light mx-3"></hr>

            <div style={{ fontSize: "20px", fontWeight: "400" }}>
              Time Slots
            </div>

            {this.state.timeSlots.length ? (
              <div style={{ fontSize: "18px", fontWeight: "400" }}>
                {this.state.timeSlots.map((timeSlot) => (
                  <div style={{ margin: "8px" }} key={Date.now}>
                    <span>{timeSlot.startTime}</span>
                    <span>{" to "}</span>
                    <span>{timeSlot.endTime}</span>
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="outline-secondary"
                      onClick={(e) =>
                        this.setState({
                          timeSlots: this.state.timeSlots.filter(
                            (f) =>
                              timeSlot.startTime !== f.startTime &&
                              timeSlot.endTime !== f.endTime
                          ),
                        })
                      }
                    >
                      X
                    </Button>{" "}
                  </div>
                ))}
                <hr className="bg-light mx-3"></hr>
              </div>
            ) : (
              <>
                <div>No slots added</div>
                <hr className="bg-light mx-3"></hr>
              </>
            )}
            <div>
              <div>
                <div>
                  <label htmlFor="start-time">start time</label>
                </div>
                <input
                  type="time"
                  value={this.state.startTime}
                  style={{ marginLeft: "10px" }}
                  id="start-time"
                  name="startTime"
                  onChange={this.onChange}
                />
              </div>
              <div>
                <div>
                  <label htmlFor="end-time">end time</label>
                </div>
                <input
                  type="time"
                  value={this.state.endTime}
                  id="end-time"
                  name="endTime"
                  onChange={this.onChange}
                />
              </div>
              <button onClick={addTimeSlot}>Add Slot</button>
            </div>

            <hr className="bg-light mx-3"></hr>
            <div>
              <div>
                <label htmlFor="description">Description</label>
              </div>
              <input
                style={{ height: "200px" }}
                type="textarea"
                onChange={this.onChange}
                name="description"
              />
            </div>
            <div>
              <div>
                <label htmlFor="description">Description</label>
              </div>
              <input
                style={{ height: "200px" }}
                type="textarea"
                onChange={this.onChange}
                name="description"
              />
            </div>
            <hr className="bg-light mx-3"></hr>

            {/* <div className="d-flex justify-content-center">
              <div>
                <div>
                  <label className="px-3">
                    <Translate content="seats_count" />
                  </label>
                </div>
                <input
                  onChange={this.onChange}
                  name="numberOfSeats"
                  type="number"
                  className="mx-2"
                  style={{ width: "100px" }}
                />
              </div>
            </div> */}

            {/* <hr className="bg-light mx-3"></hr> */}

            {/* <div>
              {this.state.seatsLabel.map((key = 1, index) => (
                <div>
                  <div className="d-flex justify-content-center" key={key++}>
                    <div>
                      <label classNmae="px-3">
                        <Translate content="seat_type" />{" "}
                      </label>
                      <Dropdown style={{ width: "120px" }}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic2"
                          style={{ overflow: "hiddin" }}
                        >
                          {this.state.seatsLabel[index].name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {seats.map((type, key = 0) => (
                            <Dropdown.Item
                              onClick={this.onClickSeatType.bind(this, index)}
                              key={key++}
                            >
                              {type}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div>
                      <div>
                        <label classNmae="px-3">
                          <Translate content="seat_price" /> (SAR)
                        </label>
                      </div>
                      <input
                        onChange={this.onChangeSeatPrice.bind(this, index)}
                        name="priceForOneSeat"
                        type="number"
                        step="0.01"
                        className="mx-2"
                        style={{ width: "100px" }}
                      />
                    </div>

                    <div>
                      <div>
                        <label classNmae="px-3">
                          <Translate content="seat_count" />{" "}
                        </label>
                      </div>
                      <input
                        onChange={this.onChangeSeatCount.bind(this, index)}
                        name="seatCount"
                        type="number"
                        className="mx-2"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>

                  {index > 0 ? (
                    <button
                      className="btn btn-sm btn-outline-danger mb-2"
                      onClick={this.onDeleteSeat.bind(this, index)}
                    >
                      {" "}
                      x Delete
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ))}

              <div>
                <button
                  onClick={this.addMore}
                  style={{
                    background: "none",
                    border: "none",
                    color: "purple",
                  }}
                >
                  + Add more
                </button>
              </div>
            </div> */}
            <hr className="bg-light mx-3"></hr>

            <Map
              getLocation={this.getLocation}
              style={{ height: "230px", width: "400px", maxWidth: "90%" }}
            />

            <hr className="bg-light mx-3"></hr>

            <PreviewMultipleImages getFiles={this.getFiles} />
            {/* <input
              onChange={onChangeCustom}
              type="file"
              class="custom-file-input"
              id="customFile"
              name="filenames"
              style={{ height: "30px", opacity: 1 }}
            /> */}

            <div className="text-center">
              <p style={{ color: "red" }}>
                {this.state.errMsg ? this.state.errMsg : ""}
              </p>
            </div>
            <div className="text-center">
              <p className="text-success">
                {this.state.msg ? this.state.msg : ""}
              </p>
            </div>
            <div>
              {this.state.loading ? (
                <div class="spinner-border  my-2 text-center" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </div>

            <button
              onClick={this.onSubmit}
              className="btn btn-success my-4"
              style={{ background: "purple", border: "purple", width: "200px" }}
            >
              <Translate content="submit" />
            </button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.eventState.categories,
  user: state.auth.user,
  seatsTypes: state.eventState.seats,
});

export default connect(mapStateToProps, { postEvent })(eventModal);
