/* eslint-disable import/no-anonymous-default-export */
import {
    GET_SINGLE_BOOKING_START,
    GET_SINGLE_BOOKING_FAILED,
    GET_SINGLE_BOOKING_SUCCESS,

} from '../actions/types';

const initialState = {
    userBookingInfo: {},
    userBookingLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        // Event retalted actions
        // Post booking code needs to be moved here from events

        // {

        // }

        // get single booking actions
        case GET_SINGLE_BOOKING_START:
            return {
                ...state,
                userBookingLoading: true
            }
        case GET_SINGLE_BOOKING_SUCCESS:
            return {
                ...state,
                userBookingLoading: false,
                userBookingInfo: action.payload,
            }
        case GET_SINGLE_BOOKING_FAILED:
            return {
                ...state,
                userBookingLoading: false,
            }
        default:
            return state;
    }
}