/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-dupe-keys */
export default {
  dashboard: "لوحة التحكم",
  events: "الفعاليات",
  providers: "الناشرين",
  users: "المستخدمين",
  admins: "المشرفين",
  add_admin: "اضافة مشرف",
  logout: "تسجيل خروج",
  total_events: "اجمالي الفعاليات",
  total_providers: "اجمالي الناشرين",
  total_users: "اجمالي المستخدمين",
  top_events: "أعلى الفعاليات",
  address: "العنوان",
  image: "الصورة",
  provider_name: "اسم الناشر",
  event_name: "اسم الفعالية بالعربية",
  event_name_en: "اسم الفعالية بالانجليزية",
  event_type: "نوع الفعالية",
  star_name: "اسم النجم بالعربية",
  star_name_en: "اسم النجم بالانجليزية",
  event_date: "تاريخ الفعالية",
  seats_count: "عدد التذاكر",
  seat_price: "ثمن المقعد",
  booked_seats: "المقاعد المحجوزة",
  attended_count: "عدد الحضور",
  provider: "الناشر",
  filter: "تصفية",
  add_category: "اضافة تصنيفات",
  manage_chart_categories: "إدارة أسعار التصنيفات",
  add_new_event: "اضافة فعالية جديدة ",
  add_new_category: "اضافة تصنيف جديد",
  category_name_ar: "اسم التصنيف بالعربية",
  category_name_en: "اسم التصنيف بالانجليزية",
  submit: "تسجيل",
  description_ar: "وصف الفعالية بالعربية",
  description_en: "وصف الفعالية بالانجليزية",
  search_places: "بحث الاماكن",
  edit_event: "تعديل الفعالية",
  join_date: "تسجيل التاريخ",
  employers: "الموظفين",
  add_new_provider: "تسجيل ناشر جديد",
  name: "الاسم",
  email: "الايميل",
  phone: "الموبايل",
  password: "كلمة السر",
  edit_provider: "تعديل الناشر",
  update: "تحديث",
  no_employers: "لا يوجد موظفين",
  user: "المستخدم",
  user_name: "اسم المستخدم",
  edit_user: "تعديل المستخدم",
  admin_email: "ايميل المشرف",
  add_admin: "اضافة مشرف",
  booked_events: "الفعاليات المحجوزة",
  language: "اللغة",
  english: "الانجليزية",
  arabic: "العربية",
  events_count: "اجمالي الفعاليات",
  staff_count: "اجمالي الموظفين",
  live_events: "الفعاليات الحالية",
  end_events: "الفعاليات المنتهية",
  back: "عودة",
  provider_events: "حفلات الناشر",
  add_seats: "اضافة كراسي",
  add_new_seats: "اضافة كراسي جديدة",
  seat_name: "اسم المقعد ",
  seat_name_ar: "اسم المقعد بالعربية",
  seat_name_en: "اسم المقعد بالانجليزية",
  seat_count: "عدد المقاعد",
  seat_type: "نوع المقعد",
  select_chart: "اختر القاعة",
  chartName: "اسم القاعة",
  display_details: "عرض",
  tickets_info: "معلومات التذاكر",
  charge_id: "رقم التذكرة",
  date: "تاريخ حجز التذكرة",
  numberOfSeats: "عدد المقاعد",
  paidAmount: "المدفوع",
  price: "الثمن",
  fees: "الرسوم",
  scaned: "تم الحضور",
  seatType: "نوع المقعد",
  availabe_seats: "المقاعد المتاحة",
  event_details: "تفاصيل الفعالية",
  booking_details: "تفاصيل الحجز",
  event_adress: "عنوان الفعالية",
  special_seat: "كراسي خاصة",
  vip_seat: "كراسي VIP",
  book_tickets: "حجز التذاكر",
  download_qr: "تحميل رمز الاستخدام",
  promocodes: "كود الخصم",
  empty: "",
  expiry_date: "تاريخ الانتهاء",
  ticket_name: "اسم التذكرة",
  new_event: "فعالية جديدة",
  new_event_title: "إنشاء فعالية جديدة",
  tax_ratio_label: "نسبة الضريبة",
  repeated_event: "هل تتكرر الفعالية لعدة أيام؟",
  event_start_date: "تاريخ البداية",
  event_end_date: "تاريخ النهاية",
  event_times_slots_label: "توقيتات الفعالية",
  event_times_slots_hint: "قم باختيار مواعيد البداية والنهاية",
  to_label: " إلى ",
  start_time: "موعد البدء",
  end_time: "موعد النهاية",
  button_add_event_time: "إضافة موعد",
  event_images_label: "قم باختيار صور الفعالية",
  event_location_label: "قم بالنقر على الخريطة لتحديد موقع الفعالية",
  create_event: "حفظ الفعالية",
  update_event: "تعديل الفعالية",
  event_venue_type: "ما نوع قاعة الفعالية؟",
  seat_types_label: "قم بإنشاء أنواع المقاعد وأسعارها",
  button_delete_type: "حذف النوع",
  button_add_type: "إضافة نوع آخر",
  button_create_types: "قم بإنشاء أنواع المقاعد",
  seats_manager_title: "قم بإنشاء المقاعد العادية",
  tables_manager_title: "قم بإنشاء الطاولات",
  booth_manager_title: "قم بإنشاء المقاعد الخاصة/الأكواخ",
  category_price: "سعر التصنيف"
};
