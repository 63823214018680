import React from 'react'
import {connect} from 'react-redux'
import {deleteAdmin} from  '../actions/authActions'
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Translate from 'react-translate-component'
class Admins extends React.Component{

    state = {}

    onDeleteAdmin = (id , e) => {
          this.props.deleteAdmin(id, e)
    }
        render(){

        let admins = this.props.admins


        return(
            <div className='reports justify-content-center mx-auto text-center   pt-3' style={{ width: '80%' }}>
                <div className='card shadow mx-auto text-center ' style={{ marginTop: '50px', maxWidth: '90%', width: '900px', height: '400px', color: 'purple', overflow: 'auto' }}>
<table  >

    <thead className='mx-auto ' style={{ borderBottom: '.5px solid #ff5e00 ' }}>
        <tr>
           

            <th ><Translate content = 'admin_email'/></th>
            <th ><Translate content = 'join_date'/></th>
           

        </tr>
    </thead>

    <tbody >
        {admins.map(( admin, key = 0) => (
            <tr key = {key++}>


<td >{admin.email}</td>

            <td >{admin.date}</td>
            
         {/** <td > <button onClick ={this.onClickEditProvider.bind(this, provider._id)} id='btn-light'><FontAwesomeIcon icon={faUserEdit} /></button></td>

            <td > <button onClick={this.onDeactivate.bind(this, provider._id, provider.active)} id='btn-light'><FontAwesomeIcon icon={provider.active ? faCheckCircle : faBan} /></button></td> */}

            <td > <button onClick = {this.onDeleteAdmin.bind(this, admin._id)} id='btn-light'><FontAwesomeIcon icon={faTrash} /></button></td>
        </tr>
        ))}
    </tbody>



</table>
</div>
            </div>
        )
    }
}

const mapState = (state) => ({
    admins : state.auth.admins
})
export default connect(mapState, {deleteAdmin}) (Admins)