/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_PROVIDER,
    GET_PROVIDER,
    UPDATE_PROVIDER,
    DELETE_PROVIDER,
    GET_PROVIDERS,
    GET_EMPLOYERS,
    GET_PROVIDER_EMPLOYERS,
    CLEAR_PROVIDER

} from '../actions/types'

const initialState = {
    providers: [],
    employers: [],
    providerData: {provider: '', employers: ''}
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_PROVIDER:

            return {
                ...state,
                providers: [action.payload.user, ...state.providers]
            }

        case GET_PROVIDERS:

            return {
                ...state,
                providers: action.payload
            }

        case GET_PROVIDER:
            return {
                ...state,
                providerData: {provider: action.payload, employers : state.providerData.employers}
            }

        case DELETE_PROVIDER:

        
            return {
                ...state,
               providers: state.providers.filter(provider => provider._id !== action.payload),
               providerData: state.providerData.employers ?

               {provider : state.providerData.provider, 
                employers: state.providerData.employers.filter(employer => 
                    employer._id !== action.payload
                )} : state.providerData
            }

        case UPDATE_PROVIDER:

            return {
                ...state,
                providers: state.providers.map(provider => {
                    if (provider._id === action.payload._id) {
                        return action.payload
                    } else {
                        return provider
                    }
                })
            }
        case GET_EMPLOYERS:
            return {
                ...state,
                employers: action.payload
            }
        case GET_PROVIDER_EMPLOYERS:
            return {
                ...state,
                providerData: { provider:state.providerData.provider ,employers:  action.payload[0] ?  action.payload : false }
            }
            case CLEAR_PROVIDER:
                return {
                    ...state,
                    providerData: {provider: '', employers:''}
                }

        default:
            return state
    }
}