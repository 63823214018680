import React from "react";
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import Map from "./googlemap";
import { connect } from "react-redux";
import { updateEvent } from "../actions/eventsActions";
import axios from "axios";
import Translate from "react-translate-component";
const BASE_SERVER = process.env.REACT_APP_BASE_SERVER;
class editEventModal extends React.Component {
  state = {
    show: false,
    type: this.props.event.category || "all",
    seatsLabel: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onClickType = (e) => {
    this.setState({
      type: e.target.innerText,
    });
  };

  getLocation = (address, latLng) => {
    this.setState({
      address,
      latLng,
    });
  };

  onDeleteSeat = (index, e) => {
    this.setState({
      seatsLabel: this.state.seatsLabel
        ? this.state.seatsLabel.filter((seat, number) => number !== index)
        : this.props.event.seatsTypes.filter(
            (seat, number) => number !== index
          ),
    });
  };

  onClickSeatType = (index, e) => {
    let seats = this.state.seatsLabel || this.props.event.seatsTypes;
    seats[index].name = e.target.innerText;

    this.setState({
      seatsLabel: seats,
    });
  };

  onChangeSeatPrice = (index, e) => {
    let seats = this.state.seatsLabel || this.props.event.seatsTypes;
    seats[index].price = Number(e.target.value);

    this.setState({
      seatsLabel: seats,
    });
  };

  onChangeSeatCount = (index, e) => {
    let seats = this.state.seatsLabel || this.props.event.seatsTypes;
    seats[index].count = Number(e.target.value);

    this.setState({
      seatsLabel: seats,
    });
  };

  onSubmit = (e) => {
    this.setState({ errMsg: false, msg: false, loading: true });

    let id = this.props.event._id;

    let {
      name,
      starName,
      description,
      date,
      numberOfSeats,
      address,
      latLng,
      imageFile,
      imageUrl,
      seatsLabel,
    } = this.state;

    let body = {
      name,
      starName,
      category: this.state.type,
      description,
      date,
      numberOfSeats,
      seatsType: seatsLabel,
      address,
      lat: latLng ? latLng.lat : false,
      lng: latLng ? latLng.lng : false,
      imageUrl,
      numberOfAvailableSeats: numberOfSeats,
    };

    if (imageFile) {
      //upload file
      const formData1 = new FormData();
      formData1.append("file", imageFile);
      axios
        .post(BASE_SERVER + "/upload/", formData1, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data) {
            body.imageUrl = res.data.imageUrl;

            this.props.updateEvent(id, body);

            this.setState({
              msg: "event updated successfully",
              loading: false,
            });
          }
        });
    } else {
      this.props.updateEvent(id, body);
      this.setState({
        msg: "event updated successfully",
        loading: false,
      });
    }
  };

  render() {
    const onChangeCustom = (e) => {
      const element = document.getElementById("customFile2");
      imageIsLoaded1(element.files[0]);
    };

    const imageIsLoaded1 = (file) => {
      this.setState({
        imageFile: file,
      });
    };

    let categories = this.props.categories;
    let event = this.props.event || "";
    let seats = this.props.seatsTypes;
    let seatsTypes = this.state.seatsLabel || event.seatsTypes || [];
    

    return (
      <div>
        <Modal
          show={this.props.editEventModal}
          onHide={this.props.handleEditEventModal}
          style={{ color: "purple" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate content="edit_event" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div>
              <input
                onChange={this.onChange}
                name="name"
                type="text"
                defaultValue={event.name}
              />
            </div>
            <div>
              <input
                onChange={this.onChange}
                name="starName"
                type="text"
                defaultValue={event.starName}
              />
            </div>

            <div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic2">
                  <Translate content="event_type" />({this.state.type})
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {categories.map((type, key = 0) => (
                    <Dropdown.Item onClick={this.onClickType} key={key++}>
                      {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div>
              <input
                type="datetime-local"
                onChange={this.onChange}
                name="date"
              />
            </div>

            <div>
              <textArea
                onChange={this.onChange}
                name="description"
                placeholder={event.description}
              />
            </div>

            <hr className="bg-light mx-3"></hr>

            <div className="d-flex justify-content-center">
              <div>
                <div>
                  <label className="px-3">
                    <Translate content="seats_count" />
                  </label>
                </div>
                <input
                  defaultValue={event.numberOfSeats}
                  onChange={this.onChange}
                  name="numberOfSeats"
                  type="number"
                  className="mx-2"
                  style={{ width: "100px" }}
                />
              </div>
            </div>

            <hr className="bg-light mx-3"></hr>

            <div>
              {/**seats**/}
              {seatsTypes.map((seat, index) => (
                <div>
                  <div className="d-flex justify-content-center" key={index}>
                    <div>
                      <label classNmae="px-3">
                        <Translate content="seat_type" />{" "}
                      </label>
                      <Dropdown style={{ width: "120px" }}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic2"
                          style={{ overflow: "hiddin" }}
                        >
                          {seat.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {seats.map((type, key = 0) => (
                            <Dropdown.Item
                              onClick={this.onClickSeatType.bind(this, index)}
                              key={key++}
                            >
                              {type}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div>
                      <div>
                        <label classNmae="px-3">
                          <Translate content="seat_price" />
                          (SAR)
                        </label>
                      </div>
                      <input
                        onChange={this.onChangeSeatPrice.bind(this, index)}
                        name="priceForOneSeat"
                        defaultValue={seat.price}
                        type="number"
                        className="mx-2"
                        style={{ width: "100px" }}
                      />
                    </div>

                    <div>
                      <div>
                        <label classNmae="px-3">
                          <Translate content="seat_count" />{" "}
                        </label>
                      </div>
                      <input
                        onChange={this.onChangeSeatCount.bind(this, index)}
                        name="seatCount"
                        type="number"
                        defaultValue={seat.count}
                        className="mx-2"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>

                  {index > 0 ? (
                    <button
                      className="btn btn-sm btn-outline-danger mb-2"
                      onClick={this.onDeleteSeat.bind(this, index)}
                    >
                      {" "}
                      x Delete
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ))}

              <div>
                <button
                  onClick={this.addMore}
                  style={{
                    background: "none",
                    border: "none",
                    color: "purple",
                  }}
                >
                  + Add more
                </button>
              </div>
            </div>

            <hr className="bg-light mx-3"></hr>

            <Map
              center={{ lat: event.lat, lng: event.lng }}
              address={event.address}
              getLocation={this.getLocation}
              style={{ height: "230px", width: "400px", maxWidth: "90%" }}
            />

            <hr className="bg-light mx-3"></hr>

            <input
              onChange={onChangeCustom}
              type="file"
              class="custom-file-input"
              id="customFile2"
              name="filename"
              style={{ height: "30px", opacity: 1 }}
            />

            <div className="text-center">
              <p style={{ color: "red" }}>
                {this.state.errMsg ? this.state.errMsg : ""}
              </p>
            </div>
            <div className="text-center">
              <p className="text-success">
                {this.state.msg ? this.state.msg : ""}
              </p>
            </div>
            <div>
              {this.state.loading ? (
                <div class="spinner-border  my-2 text-center" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </div>

            <button
              onClick={this.onSubmit}
              className="btn btn-success my-4"
              style={{ background: "purple", border: "purple", width: "200px" }}
            >
              <Translate content="submit" />
            </button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.eventState.categories,
  user: state.auth.user,
  event: state.eventState.event,
  seatsTypes: state.eventState.seats,
});

export default connect(mapStateToProps, { updateEvent })(editEventModal);
