import React from 'react'
import { faEdit, faFilter, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PromocodeModal from './promocodeModal'
import EditProviderModal from './editProviderModal'
import ProviderPage from './providerPage'
import { connect } from 'react-redux'
import { getPromocodes, addPromocode, deletePromocode, updatePromocode } from '../actions/promocodeActions'
import Translate from 'react-translate-component'

class Promocodes extends React.Component {

    state = {
        eventActive: false,
        PromocodeModal: false,
        editProviderModal: false,
        provider: false
    }

    onChange = (e) => {
        console.log(e.target.name)

        let { providers, events } = this.props

        let provider = providers.filter(pro => {
            if (e.target.name === 'provider') {
                return pro.name.indexOf(e.target.value) !== -1
            } else if (e.target.name === 'date') {
                if (pro.date) {
                    return pro.date.indexOf(e.target.value) !== -1
                }
            } else if (e.target.name === 'event_name') {

                let event = events.filter(event => event.name.indexOf(e.target.value) !== -1)


                let providersId = event.map(e => e.provider_id)

                return providersId.indexOf(pro._id) !== -1
            }
        })


        if (e.target.name != '') {
            this.setState({
                providers: provider
            })
        } else {
            this.setState({
                providers: this.props.providers
            })
        }



    }

    onDeleteProvider = (id, e) => {
        this.props.deleteProvider(id)
        this.props.getProviderEmployers(id)

    }

    onDeactivate = (id, active, e) => {


        this.props.updateProvider(id, { active: !active })
    }

    onClickProvider = (id, e) => {

        this.props.getProvider(id)
        this.props.getProviderEvents(id)
        this.props.getProviderEmployers(id)
        this.setState({
            provider: !this.state.provider
        })
    }

    onClickNewProvider = (e) => {
        this.setState({
            providerModal: !this.state.providerModal
        })
    }

    onClickEditProvider = (id, e) => {

        this.props.getProvider(id)
        this.props.getProviderEmployers(id)

        this.setState({
            editProviderModal: !this.state.editProviderModal
        })


    }
    handleProviderModal = (e) => {
        this.setState({
            providerModal: false
        })
    }

    handleProvider = (e) => {
        this.setState({
            provider: false
        })

        console.log(this.state)
    }

    handleEditProviderModal = (e) => {
        this.props.clearProvider()
        this.setState({
            editProviderModal: false
        })
    }

    render() {
        let { promocodes } = this.props
        let employers = this.props.employers
        let events = this.props.events
        let getEventsCount = (providerId) => {
            let providerEvents = events.filter(event => event.provider_id === providerId)
            return providerEvents.length
        }
        return (
            <div className='reports justify-content-center mx-auto text-center   pt-3' style={{ width: '90%' }}>
                {this.state.provider ? <ProviderPage handleProvider={this.handleProvider} /> :
                    <div>
                        {/** filter */}
                        <div className='filter-row row mx-auto justify-content-center pt-5'>
                            <input onChange={this.onChange} type='text' name='provider' />
                            <input onChange={this.onChange} type='date' name='date' />
                            <input onChange={this.onChange} type='text' name='event_name' />
                            <button className='btn btn-lg btn-success my-auto mx-5' style={{ height: '50px', background: 'purple', border: 'purple' }}
                            ><FontAwesomeIcon className='mr-2' icon={faFilter} style={{ fontSize: '20px' }} /><Translate content='filter' /></button>
                        </div>
                        {/** add provider model */}

                        <div className='row justify-content-center mb-5 mx-auto' style={{ marginTop: '150px' }}>
                            <button onClick={this.onClickNewProvider} className='btn btn-light mx-3 my-2 ' style={{ color: 'purple', background: '#80008017' }}>
                                <FontAwesomeIcon icon={faPlus} /> <span style={{ marginLeft: "10px" }}> Add a promocode</span>
                            </button>
                        </div>

                        <PromocodeModal providerModal={this.state.providerModal}
                            handleProviderModal={this.handleProviderModal} />

                        <EditProviderModal editProviderModal={this.state.editProviderModal}
                            handleEditProviderModal={this.handleEditProviderModal} />
                        {/** Promocdes */}
                        <div className='card shadow mx-auto text-center ' style={{ marginTop: '50px', maxWidth: '90%', minWidth: '900px', minHeight: '400px', color: 'purple', overflow: 'auto' }}>
                            <table  >

                                <thead className='mx-auto ' style={{ borderBottom: '.5px solid #ff5e00 ' }}>
                                    <tr>
                                        <th >Promocode</th>
                                        <th >Scope</th>
                                        <th >Expiry</th>
                                        <th>Value</th>
                                        <th >Created by</th>
                                        <th >{"Edit "}</th>
                                        <th >{"Delete "}</th>
                                    </tr>
                                </thead>

                                <tbody >
                                    {promocodes.map((promo, key = 0) => (
                                        <tr key={key++}>
                                            <td >  {promo.promocode}</td>
                                            <td >{promo.scope}</td>
                                            <td >{promo.expiry}</td>
                                            <td >{promo.value} %</td>
                                            <td >{promo.createdBy}</td>
                                            <td>
                                                <button style={{ margin: "8px auto", width: '100px' }} id="btn-light">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </td>
                                            <td >
                                                <button style={{ margin: "8px auto", width: '100px' }} id="btn-light">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>



                            </table>
                        </div>
                    </div>
                }
            </div>


        )
    }
}

const mapStateToProps = (state) => ({
    providers: state.providerState.providers,
    employers: state.providerState.employers,
    events: state.eventState.events,
    promocodes: state.promoState.promocodes
})

export default connect(mapStateToProps, {
    getPromocodes, addPromocode, deletePromocode, updatePromocode
})(Promocodes)