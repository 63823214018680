import React from "react";
import { connect } from "react-redux";
import { login } from "../actions/authActions";
import { clearErrors } from "../actions/errorActions";
import { Form } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
import axios from "axios";
const BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

class LoginForm extends React.Component {
  state = {
    login: true,
  };

  componentDidUpdate() {
    if (this.props.auth.user) {
      window.location.href = "/dashboard";
    }
  }

  onClickLogin = (e) => {
    this.setState({ login: true });
  };

  onClickPassword = (e) => {
    this.setState({ login: false });
  };

  onSubmitPassword = () => {
    let email = this.state.email;

    this.setState({ errmsg: false, successmsg: "" });

    if (!email) {
      this.setState({ errmsg: "please enter your email" });
    } else {
      axios
        .post(BASE_SERVER + "/auth/forgotPassword/admin", { email })
        .then((res) => {
          this.setState({
            successmsg: "we sent you an email to recover your password !",
          });
        })
        .catch((err) => this.setState({ errmsg: err.response.data.msg }));
    }
  };
  render() {
    const onChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "type") {
        this.setState({ typemsg: null });
      }
    };
    const onSubmit = (e) => {
      e.preventDefault();
      this.props.clearErrors();
      const { email, password, type } = this.state;
      if (!type) {
        this.setState({ typemsg: "please enter login type" });
        return;
      }
      this.props.login({ email, password, type });
    };

    return (
      <div
        className="row m-0"
        style={{ position: "fixed", width: "100%", height: "100%" }}
      >
        <div className="col-lg-5 my-auto mb-5">
          <div
            className="login mx-auto my-auto"
            style={{ width: "fit-content", marginTop: "30%", maxWidth: "100%" }}
          >
            <h2 className="tone" style={{ fontSize: "35px" }}>
              tone
            </h2>

            {this.state.login ? (
              <div>
                <div>
                  <input
                    onChange={onChange}
                    name="email"
                    type="text"
                    placeholder="enter email"
                    style={{ maxWidth: "90%", width: "400px", height: "55px" }}
                  />
                </div>
                <div>
                  <input
                    onChange={onChange}
                    name="password"
                    type="password"
                    placeholder="enter password"
                    style={{ maxWidth: "90%", width: "400px", height: "55px" }}
                  />
                </div>
                <div>
                  <select
                    onChange={onChange}
                    name="type"
                    style={{ maxWidth: "90%", width: "400px", height: "55px" }}
                  >
                    <option value="">Select type</option>
                    <option value="admin">Admin</option>
                    <option value="provider">Provider</option>
                  </select>
                </div>
                <div class="mt-4">
                  <a onClick={this.onClickPassword} className="ml-2">
                    forgot password ?
                  </a>
                </div>
                <div className="text-center">
                  <p className="" style={{ color: "red", width: "90%" }}>
                    {this.props.error.id === "login failed"
                      ? this.props.error.msg.msg
                      : ""}
                    {this.state.typemsg}
                  </p>
                </div>
                <div className="text-center" style={{ maxWidth: "90%" }}>
                  <button
                    onClick={onSubmit}
                    className="btn btn-success mx-auto px-4 py-2 mt-3 "
                    style={{
                      background: "purple",
                      border: "purple",
                      width: "80%",
                      borderRadius: "20px",
                    }}
                  >
                    sumbit
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="login mx-auto my-auto"
                style={{
                  width: "fit-content",
                  marginTop: "30%",
                  maxWidth: "100%",
                }}
              >
                <div>
                  <input
                    onChange={onChange}
                    name="email"
                    type="text"
                    placeholder="enter email"
                    style={{ maxWidth: "90%", width: "400px", height: "55px" }}
                  />
                </div>

                <a onClick={this.onClickLogin} className="ml-2">
                  login
                </a>

                <div className="text-center">
                  <p className="" style={{ color: "red", width: "90%" }}>
                    {this.state.errmsg ? this.state.errmsg : ""}
                  </p>
                </div>

                <div className="text-center">
                  <p className="text-success" style={{ width: "90%" }}>
                    {this.state.successmsg ? this.state.successmsg : ""}
                  </p>
                </div>

                <div className="text-center" style={{ maxWidth: "90%" }}>
                  <button
                    onClick={this.onSubmitPassword}
                    className="btn btn-success mx-auto px-4 py-2 mt-3 "
                    style={{
                      background: "purple",
                      border: "purple",
                      width: "80%",
                      borderRadius: "20px",
                    }}
                  >
                    sumbit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="col-lg-7"
          style={{ background: "linear-gradient(to top, #df614d , #934f93)" }}
        >
          <div
            className="pl-0 pl-md-5 ml-0 ml-md-4"
            style={{ maxWidth: "100%" }}
          >
            <h2
              className="text-light tone"
              style={{ fontSize: "140px", marginTop: "25%" }}
            >
              tone
            </h2>

            <Zoom>
              <hr id="tone-hr" />
            </Zoom>

            <div>
              <h4 className="text-light ml-4">tone app dashboard</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps, { login, clearErrors })(LoginForm);
