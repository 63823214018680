import { createContext } from "react";

const AppContext = createContext({
  isVenue: false,
  setIsVenue: () => { },
  //regular
  seatsAvailable: 0,
  allSeats: [],
  setAllSeats: () => { },
  setSeatsAvailable: () => { },
  //venue
  chartKey: undefined,
  chartCategories: {},
  seats: [],
  topTables: [],
  bottomTables: [],
  rightTables: [],
  leftTables: [],
  topObjects: [],
  bottomObjects: [],
  rightObjects: [],
  leftObjects: [],
  types: [],
  allTypes: [],
  selectedSeats: [],
  selectedTables: [],
  selectedObjects: [],
  columns: 0,
  rows: 0,
  setSeats: () => { },
  setTopTables: () => { },
  setBottomTables: () => { },
  setRightTables: () => { },
  setLeftTables: () => { },
  setTopObjects: () => { },
  setBottomObjects: () => { },
  setRightObjects: () => { },
  setLeftObjects: () => { },
  setTypes: () => { },
  setAllTypes: () => { },
  setSelectedSeats: () => { },
  setSelectedTables: () => { },
  setSelectedObjects: () => { },
  setColumns: () => { },
  setRows: () => { },
  setChartKey: () => { },
  setChartCategories: () => { },
});

export default AppContext;
