
export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const GET_ADMINS = 'GET_ADMINS'
export const DELETE_ADMIN = 'DELETE_ADMIN'
export const EDIT_ADMIN = 'EDIT_ADMIN'

export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENT = 'GET_EVENT'
export const POST_EVENT = 'POST_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const PROVIDER_EVENTS = 'PROVIDER_EVENTS'
export const TOP_EVENTS = 'TOP_EVENTS'
export const CLEAR_EVENT = 'CLEAR_EVENT'


export const ADD_CATEGORY = 'ADD_CATEGORY'
export const GET_CATEGOIES = 'GET_CATEGORIES'
export const DELETE_CATEGOIES = 'DELETE_CATEGORIES'

export const ADD_SEAT = 'ADD_SEAT'
export const GET_SEATS = 'GET_SEATS'
export const DELETE_SEATS = 'DELETE_SEATS'

export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_EMPLOYERS = 'GET_EMPLOYERS'
export const GET_PROVIDER = 'GET_PROVIDER'
export const GET_PROVIDER_EMPLOYERS = 'GET_PROVIDER_EMPLOYERS'
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER'
export const DELETE_PROVIDER = 'DELETE_PROVIDER'
export const ADD_PROVIDER = 'ADD_PROVIDER'
export const CLEAR_PROVIDER = 'CLEAR_PROVIDER'

export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER';
export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER = 'UPDATE_USERS'
export const GET_BOOKING = 'GET_BOOKING'
export const CLEAR_USER = 'CLEAR_USER'

export const BOOKED_EVENTS = 'BOOKED_EVENTS';

// Booking related types
export const BOOK_TICKET_START = 'BOOK_TICKET_START';
export const BOOK_TICKET_SUCCESS = 'BOOK_TICKET_SUCCESS';
export const BOOK_TICKET_FAILED = 'BOOK_TICKET_FAILED';

export const GET_SINGLE_BOOKING_START = 'GET_SINGLE_BOOKING';
export const GET_SINGLE_BOOKING_FAILED = 'GET_SINGLE_BOOKING_FAILED';
export const GET_SINGLE_BOOKING_SUCCESS = 'GET_SINGLE_BOOKING_SUCCESS';


export const ADD_PROMOCODE = 'ADD_PROMOCODE'; 
export const GET_PROMOCODE = 'GET_PROMOCODE';
export const GET_PROMOCODES = 'GET_PROMOCODES';
export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE';
export const DELETE_PROMOCODE  = 'DELETE_PROMOCODE';
export const ERROR_PROMOCODE = 'ERROR_PROMOCODE';
export const CLEAR_PROMO_ERROS = 'CLEAR_PROMO_ERROS';