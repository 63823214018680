import React from 'react'
import { Modal } from 'react-bootstrap'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import Translate from 'react-translate-component';
import { SeatsioClient, Region, ChartListParams } from 'seatsio';

var context = undefined;
class ChartCategoryModal extends React.Component {


    state = {
        categoryList: [],
        priceMap: {}
    }
    interval = undefined
    constructor(props) {
        super(props);
        context = this;
    }
    componentDidMount() {
        this.interval = setInterval(this.refreshCategories, 5000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    async refreshCategories() {

        if (context.props && context.props.chartKey) {
            // this.setState({
            //     chartKey: this.props.chartKey
            // });
            let client = new SeatsioClient(Region.EU(), "33a07937-130e-431d-bc94-2ecb093d76d8");
            let categoryList = await client.charts.listCategories(context.props.chartKey);
            context.setState({
                categoryList: categoryList
            });
        }
    }

    onChange = (e, category) => {
        let updatedPriceMap = this.state.priceMap;
        updatedPriceMap[category.key] = parseFloat(e.target.value);
        this.setState({
            priceMap: updatedPriceMap
        });
    }

    onSubmit = (e) => {
        this.props.onSubmit(this.state.priceMap);
        this.props.handleCategoryModal(false);
    }


    render() {
        console.log("this.state.priceMap", this.state.priceMap);
        return (
            <div>

                <Modal show={this.props.categoryModal} onHide={this.props.handleCategoryModal} style={{ color: 'purple' }} className='text-center'>
                    <Modal.Header closeButton >
                        <Modal.Title >
                            <Translate content='add_new_category' />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>

                        <ul className='text-center' style={{ width: '100%', listStyle: 'none', paddingInlineStart: '0px' }}>

                            {context.state.categoryList.map((type, key = 0) => (
                                <li key={key++}>{type.label}
                                    <br />
                                    <input type='number' attributes={{ placeholder: 'category_price' }} onChange={(e) => this.onChange(e, type)} value={this.state.priceMap[type.key]} style={{ height: '40px' }} />
                                </li>
                            ))}

                        </ul>
                        <button onClick={this.onSubmit} type='submit' className='btn btn-success ml-2 mb-1 ' style={{ background: 'purple', border: 'purple' }}>
                            <Translate content='submit' />
                        </button>
                    </Modal.Body>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, {})(ChartCategoryModal)