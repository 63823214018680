function ImagesGallery({images}){
    return(
        <div className="row">
        {
        images.map((url)=>{
            return (
                <div>
                <div style={{padding:"1rem"}}>
                <img src={url} style={{height:"130px", objectFit:"contain" }} />
                </div>
                </div>
            )
        })
        }
        
        </div>
    )
}
export default ImagesGallery;