import React from 'react'
import landing8 from './images/landing8.jpg'
import appstore from './images/appstore.png'
import applestore from './images/applestore.png'

import Zoom from 'react-reveal/Zoom'

class download extends React.Component {
    render() {
        return (
            <div>
                <div style={{ backgroundImage: `url(${landing8})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%', height: '700' }}>
                    <div className='text-center text-light pt-5 ' style={{ width: '100%', height: '700px', background: '#000000a1' }}>
                        <p className='pt-5 mt-5' dir="rtl" style={{ fontSize: '35px' }}>جرّب Tone الآن وحرّك عالمك! </p>

                        <p dir="rtl" style={{ maxWidth: '600px', margin: '0 auto', fontSize: '22px' }}>
                            استمتع بتجربة مدهشة مع Tone، وجرب ميزاته، ونحن على يقين من أنه سيحول لحظاتك إلى كنز!
                        </p>

                        <Zoom>
                            <hr style={{ borderTop: '1px solid rgb(255, 129, 151)', width: '150px' }} />
                        </Zoom>

                        <div className='pt-5 row mx-0 justify-content-center'>
                            <a href='https://play.google.com/store/apps/details?id=com.hakaya.tone' target='_blank' className='my-2 mx-2' >
                                <img alt='img' src={appstore} />
                            </a>

                            <a href='https://apps.apple.com/us/app/tone-application/id1568928701' target='_blank' className='my-2 mx-2' >
                                <img alt='img' src={applestore} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default download