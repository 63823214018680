import QRCode from "qrcode.react";
import "./styles.css";
import landing4 from "../images/landing1.png";
import n from "../images/landing3.png";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import exportAsImage from "../../utils/exportAsImage";
export default function Index() {
  const exportRef = useRef();
  const { id } = useParams();
  return (
    <div className="event-qr-container">
      <div ref={exportRef}>
        <img src={landing4} alt="qr" className="event-qr-background" />
        <img src={n} alt="qr" className="event-qr-icon" />

        <div className="event-qr-body">
          <h1 className="event-qr-header">Scan the QR code to book seats</h1>
          <di>
            <QRCode value={`https://landing.toneap.com/events/${id}`} size={200} />
          </di>
        </div>
      </div>
      <div className="button-container">
        <button
          className="glow-on-hover"
          onClick={() => exportAsImage(exportRef.current, "QR")}
        >
          Download
        </button>
      </div>
    </div>
  );
}
