import React from 'react'
import {Modal} from 'react-bootstrap'
import {connect} from 'react-redux'
import {register} from '../actions/authActions'
import {clearErrors} from '../actions/errorActions'
import Translate from 'react-translate-component'

class AdminModal extends React.Component{

    state = {}

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    register = ( e) => {

        this.props.clearErrors()

        this.setState({successmsg: ''})
        let {email, password} = this.state

        let body = {email, password, type: 'admin'} 

        this.props.register( body)

       setTimeout(() => {
        if(this.props.error.id !== 'register failed'){
            console.log('success')
            this.setState({
                successmsg: 'admin added Successfully'
            })
        }
       }, 500);
    }

    onHide = (e) => {
        this.props.handleAdminModal()
        this.props.clearErrors()
        this.setState({successmsg: ''})
    }
   
    render(){
   
        return(
            <div>
                
                <Modal show={this.props.adminModal} onHide = {this.onHide} style={{color: 'purple'}}>
                    <Modal.Header closeButton >
                        <Modal.Title ><Translate content = 'add_admin'/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className = 'text-center'>
                          
                      <Translate component='input' attributes = {{placeholder: 'email'}} onChange = {this.onChange} name= 'email' type= 'email'  />

                      <Translate component='input' attributes = {{placeholder: 'password'}} onChange = {this.onChange} name= 'password' type= 'password' />

                      <div>
                          <p className = 'text-danger'>{this.props.error.id === 'register failed' ? this.props.error.msg.msg : ''}</p>
                      </div>

                      
                      <div>
                          <p className = 'text-success'>{this.props.error.id !== 'register failed' ? this.state.successmsg : ''}</p>
                      </div>
                       <div>
                       <button onClick= {this.register} className ='btn btn-success my-3' style={{background: 'purple', border: 'purple'}}><Translate content = 'submit'/></button>

                       </div>                        
                        </Modal.Body>
                </Modal>
                
            </div>
        )
    }
}

const mapState = (state) => ({
   error: state.error
})
export default  connect(mapState, {register, clearErrors}) (AdminModal)