export default {
  dashboard: "dashboard",
  events: "events",
  providers: "providers",
  users: "users",
  admins: "admins",
  add_admin: "add admin",
  logout: "logout",
  total_events: "total events",
  total_providers: "total providers",
  total_users: "total users",
  top_events: "top events",
  image: "image",
  address: "address",
  provider_name: "provider name",
  event_name: "Event name in arabic",
  event_name_en: "Event name in english",
  event_type: "Event type",
  star_name: "Star name in arabic",
  star_name_en: "Star name in english",
  event_date: "Event date",
  seats_count: "Seats count",
  seat_price: "seat price",
  booked_seats: "booked seats",
  attended_count: "attended count",
  provider: "provider",
  filter: "filter",
  add_category: "add category",
  manage_chart_categories: "Manage categories prices",
  add_new_event: "add new event",
  add_new_category: "add new category",
  category_name_ar: "category name in arabic",
  category_name_en: "category name in english",
  submit: "submit",
  description_ar: "Description in arabic",
  description_en: "Description in english",
  search_places: "search places",
  edit_event: "edit event",
  join_date: "join date",
  employers: "employers",
  add_new_provider: "add new provider",
  name: "name",
  email: "email",
  phone: "phone",
  password: "password",
  edit_provider: "edit provider",
  update: "update",
  no_employers: "no employers",
  user: "user",
  user_name: "user name",
  edit_user: "edit user",
  admin_email: "admin email",
  add_admin: "add admin",
  booked_events: "booked events",
  language: "Language",
  english: "english",
  arabic: "arabic",
  events_count: "events count",
  staff_count: "staff count",
  live_events: "live events",
  end_events: "end events",
  back: "back",
  provider_events: "provider_events",
  add_seats: "add seats",
  add_new_seats: "add new seats",
  seat_name: "seat name",
  seat_name_ar: "seat name in arabic",
  seat_name_en: "seat name in english",
  seat_count: "seat count",
  seat_type: "seat type",
  select_chart: "select chart",
  chartName: "chart name",
  display_details: "display",
  tickets_info: "tickets information",
  charge_id: "Ticket number",
  date: "ticket date",
  numberOfSeats: "number of seats",
  paidAmount: "paid",
  price: "price",
  fees: "fees",
  scaned: "attended",
  seatType: "seat type",
  available_seats: "availabe seats",
  event_details: "event details",
  booking_details: "booking details",
  event_adress: "event adress",
  special_seat: "special seat",
  vip_seat: "vip seat",
  book_tickets: "book tickets",
  download_qr: "download qr code",
  promocodes: "promocodes",
  empty: "",
  expiry_date: "Expiry date",
  ticket_name: "Ticket name",
  new_event: "new event",
  new_event_title: "New Event",
  tax_ratio_label: "Tax ratio",
  repeated_event: "Repeat event?",
  event_start_date: "Start Date",
  event_end_date: "End Date",
  event_times_slots_label: "Selected event times",
  event_times_slots_hint: "Please Select Event Times Below",
  to_label: " To ",
  start_time: "Start time",
  end_time: "End time",
  button_add_event_time: "Add event time",
  event_images_label: "Please select event images",
  event_location_label: "Please set event location on Map",
  create_event: "Create event",
  update_event: "Update event",
  event_venue_type: "What is your venue type?",
  seat_types_label: "Please fill each seats type",
  button_delete_type: "Delete type",
  button_add_type: "Add type",
  button_create_types: "Create seats types",
  types_manager_title: "Types Manager",
  seats_manager_title: "Set your seats",
  tables_manager_title: "Set your tables",
  booth_manager_title: "Set your special booths",
  category_price: "Category price"

};
