import React from 'react'
import { Link } from 'react-router-dom'

class ToneInfo extends React.Component {
    render() {
        return (
            <div>
                <div className='row mx-0  contactRow text-center' style={{ width: '100%', height: '800px' }}>
                    <div className='col-lg-12 mx-auto contact pb-5' style={{ paddingTop: '70px', }}>
                        <div className='text-center'> <p style={{ fontSize: '25px' }}>About Tone</p></div>
                        <div className='text-center'> <p style={{ fontSize: '25px' }}>الاسم: مؤسسة تون بلس للفعاليات الترفيهية
                        </p></div>
                        <div className='text-center'> <p style={{ fontSize: '25px' }}>السجل التجاري: ١٠١٠٧٢٢١٤٦
                        </p></div>
                        <div className='text-center'> <p style={{ fontSize: '25px' }}>
                            الموقع:
                            <br />
                            مدينة الرياض
                            <br />
                            ٦٣٧٦، التخصصي - منطقة الرياض
                            <br />                   وحدة رقم: ٦٣٨٤
                            <br />    الرياض١٢٣١٢-٣٤٨٠
                        </p></div>
                        <div className='text-center'> <p style={{ fontSize: '25px' }}>

                            رقم التواصل والايميل:
                            <br />  Phone: 0553554481
                            <br />   Email: info@toneap.com

                        </p></div>
                        <div>
                            <Link to="/terms">  <p style={{ fontSize: '25px' }}>الشروط والأحكام
                            </p></Link>

                        </div>


                    </div>




                </div>
            </div>
        )
    }
}

export default ToneInfo