import axios from "axios";
import { returnErrors } from "./errorActions";
import {
  ADD_PROVIDER,
  GET_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  GET_PROVIDERS,
  GET_EMPLOYERS,
  GET_PROVIDER_EMPLOYERS,
  CLEAR_PROVIDER,
} from "./types";
var BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

//get providers
export const getProviders = () => (dispatch) => {
  axios.get(BASE_SERVER + "/auth/providers").then((res) =>
    dispatch({
      type: GET_PROVIDERS,
      payload: res.data,
    })
  );
};

//get employers

export const getEmployers = () => (dispatch) => {
  axios.get(BASE_SERVER + "/auth/employers").then((res) =>
    dispatch({
      type: GET_EMPLOYERS,
      payload: res.data,
    })
  );
};

//get provider
export const getProvider = (id) => (dispatch) => {
  axios.get(BASE_SERVER + `/auth/provider/${id}`).then((res) =>
    dispatch({
      type: GET_PROVIDER,
      payload: res.data,
    })
  );
};

//get provider employers
export const getProviderEmployers = (id) => (dispatch) => {
  axios.get(BASE_SERVER + `/auth/${id}/employers`).then((res) =>
    dispatch({
      type: GET_PROVIDER_EMPLOYERS,
      payload: res.data,
    })
  );
};

//clear provider
export const clearProvider = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROVIDER,
  });
};

//delete provider
export const deleteProvider = (id) => (dispatch) => {
  axios.delete(BASE_SERVER + `/auth/employer/${id}`).then((res) =>
    dispatch({
      type: DELETE_PROVIDER,
      payload: res.data,
    })
  );
};

//update provider
export const updateProvider = (id, body) => (dispatch) => {
  axios.put(BASE_SERVER + `/auth/employer/${id}`, body).then((res) =>
    dispatch({
      type: UPDATE_PROVIDER,
      payload: res.data,
    })
  );
};

//add provider
export const addProvider = (body) => (dispatch) => {
  axios
    .post(BASE_SERVER + `/auth/provider/signup`, body)
    .then((res) =>
      dispatch({
        type: ADD_PROVIDER,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch(
        returnErrors(
          err.response.data,
          err.response.status,
          "add provider error"
        )
      );
    });
};
