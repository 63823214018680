import {
    USER_LOADING,
    USER_LOADED,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_SUCCESS,
    AUTH_ERROR,
    EDIT_ADMIN,
    DELETE_ADMIN,
    GET_ADMINS,
    REGISTER_SUCCESS,
    LOGIN_USER_START,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,

} from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    userId: localStorage.getItem('userId'),
    userData: localStorage.getItem('userData'),
    customerUserData: localStorage.getItem('customerUserData'),
    isAuthenticated: null,
    isLoading: false,
    user: null,
    admins: [],
    userType: localStorage.getItem('userType')
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        // user login related cases
        case LOGIN_USER_START:
            return {
                ...state,
                isLoading: true
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customerUserData: { ...action.payload?.user, token: action.payload?.token },
            }
        case LOGIN_USER_FAILED:
            return {
                ...state,
                isLoading: false,
            }

        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case USER_LOADED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.payload
            }

        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('userType', action.userType);
            localStorage.setItem('userId', action.payload.user._id);
            localStorage.setItem('userData', JSON.stringify(action.payload.user));
            return {
                ...state,
                ...action.payload,
                isLoading: false,
                isAuthenticated: true,
                userType: action.userType
            }
        case LOGIN_FAILED:
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token')
            localStorage.removeItem('userType')
            localStorage.removeItem('userId')
            localStorage.removeItem('userData')
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false
            }
        case GET_ADMINS:
            return {
                ...state,
                admins: action.payload
            }
        case DELETE_ADMIN:
            return {
                ...state,
                admins: state.admins.filter(admin => admin._id !== action.payload)
            }
        case EDIT_ADMIN:
            return {
                ...state,
                admins: state.admins.map(admin => {
                    if (admin._id === action.payload._id) {
                        return action.payload
                    } else {
                        return admin
                    }
                })
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                admins: [...state.admins, action.payload.user]
            }
        default:
            return state
    }
}