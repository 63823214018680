import './table.css';
import { useState } from 'react';
export default function Table({ events, getSelectedEvents }) {
    const [selectedEvents, setSelctedEvents ] = useState([]);
    

    
    const handleChange = (e,event) => {
        let updateList = []
        if(e.target.checked){
            updateList = [...selectedEvents, event]
            setSelctedEvents(updateList);
        } else {    
            updateList = selectedEvents.filter(item => item._id !== event._id)
            setSelctedEvents(updateList);
        }
        getSelectedEvents(updateList);
       
    }
    return (
        <div style={{ maxHeight: "400px", overflowY: "scroll", overflowX: "hidden" }}>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Select</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Provider</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {events.map(event => (
                                    <>
                                        <tr>
                                            <td style={{maxWidth:"50px"}}>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e)=>handleChange(e,event)}
                                                        style={{ height: "20px", width: "20px", }}
                                                    />
                                                </div>
                                            </td>
                                            <td>{event.name}</td>
                                            <td>{event.provider_name}</td>
                                            <td>{event.startDate}</td>

                                        </tr></>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
