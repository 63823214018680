import React from "react";
import { faPhoneAlt, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
const BASE_SERVER = process.env.REACT_APP_BASE_SERVER;
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arabicName: "",
      englishName: "",
      licenseNumber: "",
      area: "",
      city: "",
      street: "",
      hasBranches: "null",
      contactPersonName: "",
      email: "",
      mobile: "",
      category: "null",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
    });
  }
  eraseForm() {
    this.setState({
      arabicName: "",
      englishName: "",
      licenseNumber: "",
      area: "",
      city: "",
      street: "",
      hasBranches: "null",
      contactPersonName: "",
      email: "",
      mobile: "",
      category: "null",
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    let inValidKey = this.validateState(this.state);
    if (inValidKey) {
      alert(`Please fill ${inValidKey}`);
      return;
    }
    // alert(JSON.stringify(this.state));
    //https://dev.toneap.com
    axios
      .post(BASE_SERVER + "/contactUs", this.state)
      .then((res) => {
        alert("We have received your request, will be in touch soon!");
        this.eraseForm();
      })
      .catch((err) =>
        alert("Failed to send your request, please try again later")
      );
  }
  validateState(state) {
    let inValidKey = null;
    for (const key in state) {
      if (state[key] == "" || state[key] == "null") {
        inValidKey = key;
        break;
      }
    }
    return inValidKey;
  }
  render() {
    return (
      <div>
        <div
          className="row mx-0  contactRow"
          style={{ width: "100%", height: "1000px" }}
        >
          <div
            className="col-lg-6 mx-auto text-center "
            style={{ background: "#f7f0ff", paddingTop: "70px" }}
          >
            <p style={{ fontSize: "35px" }}> contact us</p>

            <hr style={{ width: "400px" }} />
            <div className="mt-4">
              <a href="https://www.facebook.com/Tone-Ap-Official-109049984803543">
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  style={{ fontSize: "35px", margin: "15px", color: "#8834e2" }}
                />
              </a>
              <a href="https://twitter.com/ToneApOfficial1">
                <FontAwesomeIcon
                  icon={faTwitterSquare}
                  style={{ fontSize: "35px", margin: "15px", color: "#8834e2" }}
                />
              </a>
              <a href="https://www.instagram.com/toneapofficial/">
                <FontAwesomeIcon
                  icon={faInstagramSquare}
                  style={{ fontSize: "35px", margin: "15px", color: "#8834e2" }}
                />
              </a>
            </div>
          </div>
          <div
            className="col-lg-6 mx-auto contact pb-5"
            style={{ paddingTop: "70px" }}
          >
            <form onSubmit={this.handleSubmit}>
              <div style={{ width: "fit-content" }}>
                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="الاسم"
                    value={this.state.arabicName}
                    name="arabicName"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="Enlgish name"
                    value={this.state.englishName}
                    name="englishName"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="رقم السجل التجاري"
                    value={this.state.licenseNumber}
                    name="licenseNumber"
                    onChange={this.handleChange}
                  />
                </div>

                <div
                  className="pl-3"
                  style={{ paddingTop: "20px", paddingBottom: "10px" }}
                >
                  <select
                    value={this.state.category}
                    name="category"
                    onChange={this.handleChange}
                  >
                    <option value="null" disabled>
                      نوع النشاط
                    </option>
                    <option value="مطاعم وكافيهات">مطاعم وكافيهات</option>
                    <option value="فنادق وقاعات">فنادق وقاعات</option>
                    <option value="منظمين وفنانين">منظمين وفنانين</option>
                  </select>
                </div>

                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="المنطقة"
                    value={this.state.area}
                    name="area"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="المدينة"
                    value={this.state.city}
                    name="city"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="الشارع"
                    value={this.state.street}
                    name="street"
                    onChange={this.handleChange}
                  />
                </div>

                <div
                  className="pl-3"
                  style={{ paddingTop: "20px", paddingBottom: "10px" }}
                >
                  <select
                    value={this.state.hasBranches}
                    name="hasBranches"
                    onChange={this.handleChange}
                  >
                    <option value="null" disabled>
                      هل تمتلك عدة فروع؟
                    </option>
                    <option value="true">نعم</option>
                    <option value="false">لا</option>
                  </select>
                </div>
                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="اسم مسئول التواصل"
                    value={this.state.contactPersonName}
                    name="contactPersonName"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="Email"
                    value={this.state.email}
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="pl-3">
                  <input
                    type="text"
                    placeholder="Mobile"
                    value={this.state.mobile}
                    name="mobile"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="text-center" style={{ paddingTop: "30px" }}>
                  <button
                    type="submit"
                    className="btn btn-success mx-auto"
                    style={{ background: "#8834e2", border: "#8834e2" }}
                  >
                    {" "}
                    Join us{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="text-center text-light mt-5 mt-md-0"
          style={{
            height: "50px",
            width: "100%",
            background: "linear-gradient(to right, #ff5f6d, #ffc371)",
          }}
        >
          <p className="pt-3"> copy right by tone 2021</p>
        </div>
      </div>
    );
  }
}

export default Contact;
