/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_PROMOCODE,
    GET_PROMOCODE,
    UPDATE_PROMOCODE,
    DELETE_PROMOCODE,
    GET_PROMOCODES,
    ERROR_PROMOCODE,
    CLEAR_PROMO_ERROS

} from '../actions/types'

const initialState = {
    errors: "",
    promocodes: [],
    promocode: "",
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_PROMOCODE:

            return {
                ...state,
                errors: "",
                promocodes: [action.payload, ...state.promocodes]
            }

        case GET_PROMOCODE:

            return {
                ...state,
                errors: "",
                promocode: action.payload
            }

        case GET_PROMOCODES:
            return {
                ...state,
                errors: "",
                promocodes: action.payload
            }

        case DELETE_PROMOCODE:


            return {
                ...state,
                promocodes: state.promocodes.filter(promocode => promocode._id !== action.payload),
            }


        case ERROR_PROMOCODE:
            return {
                ...state,
                errors: action.payload
            }

        case CLEAR_PROMO_ERROS:
            return {
                ...state,
                errors: ""
            }

        case UPDATE_PROMOCODE:

            return {
                ...state,
                promocodes: state.promocodes.map(provider => {
                    if (provider._id === action.payload._id) {
                        return action.payload
                    } else {
                        return provider
                    }
                })
            }

        default:
            return state
    }
}