import React from 'react'
import { faBan, faCheckCircle, faUserEdit, faFilter, faPlus, faTrash, faUser, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProviderModal from './providerModals'
import EditProviderModal from './editProviderModal'
import ProviderPage from './providerPage'
import { connect } from 'react-redux'
import {
    deleteProvider, updateProvider, getProvider, getProviderEmployers,
    clearProvider
} from '../actions/providersActions'
import { getProviderEvents } from '../actions/eventsActions'
import Translate from 'react-translate-component'
import * as XLSX from 'xlsx/xlsx.mjs'

class Providers extends React.Component {

    state = {
        eventActive: false,
        ProviderModal: false,
        editProviderModal: false,
        provider: false
    }

    onChange = (e) => {
        console.log(e.target.name)

        let { providers, events } = this.props

        let provider = providers.filter(pro => {
            if (e.target.name === 'provider') {
                return pro.name.indexOf(e.target.value) !== -1
            } else if (e.target.name === 'date') {
                if (pro.date) {
                    return pro.date.indexOf(e.target.value) !== -1
                }
            } else if (e.target.name === 'event_name') {

                let event = events.filter(event => event.name.indexOf(e.target.value) !== -1)


                let providersId = event.map(e => e.provider_id)

                return providersId.indexOf(pro._id) !== -1
            }
        })


        if (e.target.name != '') {
            this.setState({
                providers: provider
            })
        } else {
            this.setState({
                providers: this.props.providers
            })
        }



    }

    onDeleteProvider = (id, e) => {
        this.props.deleteProvider(id)
        this.props.getProviderEmployers(id)

    }

    onDeactivate = (id, active, e) => {


        this.props.updateProvider(id, { active: !active })
    }

    onClickProvider = (id, e) => {

        this.props.getProvider(id)
        this.props.getProviderEvents(id)
        this.props.getProviderEmployers(id)
        this.setState({
            provider: !this.state.provider
        })
    }

    onClickNewProvider = (e) => {
        this.setState({
            providerModal: !this.state.providerModal
        })
    }

    onClickEditProvider = (id, e) => {

        this.props.getProvider(id)
        this.props.getProviderEmployers(id)

        this.setState({
            editProviderModal: !this.state.editProviderModal
        })


    }


    handleProviderModal = (e) => {
        this.setState({
            providerModal: false
        })
    }

    handleProvider = (e) => {
        this.setState({
            provider: false
        })

        console.log(this.state)
    }

    handleEditProviderModal = (e) => {
        this.props.clearProvider()
        this.setState({
            editProviderModal: false
        })
    }
    onExportExcel = (e) => {
        var workbook = XLSX.utils.book_new();
        var worksheet = XLSX.utils.json_to_sheet(this.props.providers);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'providers.xlsx');

    }

    render() {

        let providers = this.state.providers || this.props.providers
        let employers = this.props.employers
        let events = this.props.events


        let getEventsCount = (providerId) => {
            let providerEvents = events.filter(event => event.provider_id === providerId)

            return providerEvents.length
        }

        let getEmployersCount = (providerId) => {
            let providerEmployers = employers.filter(provider => provider.provider_id === providerId)

            return providerEmployers.length
        }

        return (


            <div className='reports justify-content-center mx-auto text-center   pt-3' style={{ width: '80%' }}>
                {this.state.provider ? <ProviderPage handleProvider={this.handleProvider} /> :
                    <div>
                        {/** filter */}
                        <div className='filter-row row mx-auto justify-content-center pt-5'>


                            <Translate attributes={{ placeholder: 'provider' }} component='input' onChange={this.onChange} type='text' name='provider' />
                            <input onChange={this.onChange} type='date' name='date' />
                            <Translate attributes={{ placeholder: 'event_name' }} component='input' onChange={this.onChange} type='text' name='event_name' />
                            <button className='btn btn-lg btn-success my-auto mx-5' style={{ height: '50px', background: 'purple', border: 'purple' }}
                            ><FontAwesomeIcon className='mr-2' icon={faFilter} style={{ fontSize: '20px' }} /><Translate content='filter' /></button>
                        </div>



                        {/** add provider model */}

                        <div className='row justify-content-center mb-5 mx-auto' style={{ marginTop: '150px' }}>
                            <button onClick={this.onClickNewProvider} className='btn btn-light mx-3 my-2 ' style={{ color: 'purple', background: '#80008017' }}><FontAwesomeIcon icon={faPlus} /> <Translate content='add_new_provider' /></button>
                            <button onClick={this.onExportExcel} className='btn btn-light mx-3 my-2 ' style={{ color: 'purple', background: '#80008017' }}><FontAwesomeIcon icon={faFileDownload} /> Export as Excel</button>
                        </div>

                        <ProviderModal providerModal={this.state.providerModal}
                            handleProviderModal={this.handleProviderModal} />

                        <EditProviderModal editProviderModal={this.state.editProviderModal}
                            handleEditProviderModal={this.handleEditProviderModal} />
                        {/** providers */}
                        <div className='card shadow mx-auto text-center ' style={{ marginTop: '50px', maxWidth: '90%', width: '900px', height: '400px', color: 'purple', overflow: 'auto' }}>
                            <table  >

                                <thead className='mx-auto ' style={{ borderBottom: '.5px solid #ff5e00 ' }}>
                                    <tr>
                                        <th ><Translate content='provider' /></th>

                                        <th ><Translate content='provider_name' /></th>
                                        <th ><Translate content='join_date' /></th>
                                        <th ><Translate content='expiry_date' /></th>
                                        <th ><Translate content='events' /></th>

                                        <th ><Translate content='employers' /></th>

                                    </tr>
                                </thead>

                                <tbody >
                                    {providers.map((provider, key = 0) => (
                                        <tr key={key++}>
                                            <td >
                                                {provider.imageUrl ?
                                                    <img className='my-2' src={provider.imageUrl} width='100' style={{ height: '70px', borderRadius: '18px' }} /> :
                                                    <FontAwesomeIcon className='py-2 my-2' icon={faUser} style={{
                                                        width: '100px', height: '70px', fontSize: '50px',
                                                        border: '1px solid #7400cc57', borderRadius: '18px', color: '#7400cc57'
                                                    }} />}
                                            </td>
                                            <td ><a href='#' onClick={this.onClickProvider.bind(this, provider._id)}>{provider.name}</a></td>
                                            <td >{provider.date}</td>
                                            <td >{provider.expiry_date}</td>
                                            <td >{getEventsCount(provider._id)}</td>
                                            <td >{getEmployersCount(provider._id)}</td>

                                            <td > <button onClick={this.onClickEditProvider.bind(this, provider._id)} id='btn-light'><FontAwesomeIcon icon={faUserEdit} /></button></td>

                                            <td > <button onClick={this.onDeactivate.bind(this, provider._id, provider.active)} id='btn-light'><FontAwesomeIcon icon={provider.active ? faCheckCircle : faBan} /></button></td>

                                            <td > <button onClick={this.onDeleteProvider.bind(this, provider._id)} id='btn-light'><FontAwesomeIcon icon={faTrash} /></button></td>
                                        </tr>
                                    ))}
                                </tbody>



                            </table>
                        </div>
                    </div>
                }
            </div>


        )
    }
}

const mapStateToProps = (state) => ({
    providers: state.providerState.providers,
    employers: state.providerState.employers,
    events: state.eventState.events
})

export default connect(mapStateToProps, {
    deleteProvider, updateProvider, getProvider,
    getProviderEmployers, clearProvider, getProviderEvents
})(Providers)