import React from 'react'
import {Modal} from 'react-bootstrap'
import {connect} from 'react-redux'
import {updateUser} from '../actions/usersActions'
import Translate from 'react-translate-component'

class EditUserModal extends React.Component{

    state = {}

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onSubmit = (id, e) => {

        this.setState({successmsg: ''})
        let {name, email, phone} = this.state

        let body = {name, email , phone} 

        this.props.updateUser(id, body)

        this.setState({
            successmsg: 'user updated Successfully'
        })
    }

    onHide = () => {
        this.props.handleeditUserModal()
        this.setState({successmsg: ''})
    }
    render(){
        let user = this.props.user
        return(
            <div>
                
                <Modal show={this.props.editUserModal} onHide = {this.onHide} style={{color: 'purple'}}>
                    <Modal.Header closeButton >
                        <Modal.Title >
                        <Translate content = 'edit_user'/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className = 'text-center'>
                          
                      <input onChange = {this.onChange} name= 'email' type= 'email' placeholder = 'Enter email' defaultValue = {user.email} />

                      <input onChange = {this.onChange} name= 'name' type= 'name' placeholder = 'name' defaultValue = {user.name} />
                      <input onChange = {this.onChange} name= 'phone' type= 'phone' placeholder = 'phone' defaultValue = {user.phone} />
                      
                      <div>
                          <p className = 'text-success'>{this.state.successmsg}</p>
                      </div>
                       <div>
                       <button onClick= {this.onSubmit.bind(this, user._id)} className ='btn btn-success my-3' style={{background: 'purple', border: 'purple'}}><Translate content = 'update'/></button>

                       </div>                        
                        </Modal.Body>
                </Modal>
                
            </div>
        )
    }
}

const mapState = (state) => ({
    user: state.userState.user
})
export default  connect(mapState, {updateUser}) (EditUserModal)