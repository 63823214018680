import { Flex } from "@chakra-ui/core";
import Map from "../googlemap";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Translate from 'react-translate-component';
import Modal from "react-bootstrap/Modal";
import PreviewMultipleImages from "../multi-imageInput/PreviewMultipleImages";
import { useContext } from "react";
import AppContext from "../../context/appContext";
import axios from "axios";
import EventSeatsType from "./EventSeatsType";

const BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

const EventDetails = ({ reduxState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  let {
    isVenue,
    allSeats,
    seatsAvailable,
    rows,
    columns,
    topObjects,
    bottomObjects,
    rightObjects,
    leftObjects,
    seats,
    topTables,
    bottomTables,
    rightTables,
    leftTables,
    types,
    chartKey,
    chartCategories
  } = useContext(AppContext);

  const [categories, setCategories] = useState([]);

  const [eventDetails, setEventDetails] = useState({
    name: "",
    starName: "",
    category: "",
    description: "",
    startDate: "",
    endDate: "",
    recurring: false,
    numberOfSeats: 0,
    address: "",
    lat: 0,
    lng: 0,
    timeSlots: [],
    imageUrl: [],
    numberOfAvailableSeats: 0,
    currency: "SAR",
    provider_id: "",
    provider_name: "",
    venueMap: {},
    allSeats: [],
    seatsAvailable: 0,
    ticketName: "",
    taxRatio: 0,
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookedSeats, setBookedSeats] = useState(0);
  const [timeSlot, setTimeSlot] = useState({
    startTime: "",
    endTime: "",
  });

  const [location, setLocation] = useState();
  const [marker, setMarker] = useState();

  const [oldChart, setOldChart] = useState();
  let [old_seats_io_Map, setOld_seats_io_Map] = useState();
  const [files, setFiles] = useState([]);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    // code
    const fetchCategories = async () => {
      const res = await axios.get(`${BASE_SERVER}/category`);
      if (isMounted) {
        setCategories(res.data.categories);
        setEventDetails({
          ...eventDetails,
          category: res.data.categories[0],
        });
      }
    };
    // get id of event from url 
    let url = window.location.href;
    let id = url.split("/")[url.split("/").length - 1];
    // get event by id 
    const getEvent = async () => {
      const res = await axios.get(`${BASE_SERVER}/event/${id}`);
      let startDate = new Date(res.data.startDate);
      startDate = startDate.toISOString().split("T")[0];
      let endDate = "";
      try {
        endDate = new Date(res.data.endDate);
        endDate = endDate.toISOString().split("T")[0];
      } catch (error) {
        endDate = "";
      }
      if (isMounted) {
        setEventDetails({
          ...eventDetails,
          name: res.data.name,
          name_en: res.data.name_en || "",
          starName: res.data.starName,
          starName_en: res.data.starName_en || "",
          category: res.data.category,
          description: res.data.description,
          description_en: res.data.description_en || "",
          startDate: startDate,
          endDate: endDate,
          recurring: res.data.recurring,
          numberOfSeats: res.data.numberOfSeats,
          address: res.data.address,
          lat: res.data.lat,
          lng: res.data.lng,
          timeSlots: res.data.slots,
          imageUrl: res.data.imageUrl,
          numberOfAvailableSeats: res.data.numberOfAvailableSeats,
          currency: res.data.currency,
          provider_id: res.data.provider_id,
          provider_name: res.data.provider_name,
          venueMap: res.data.venueMap,
          allSeats: res.data.seatsTypes,
          seatsAvailable: res.data.numberOfSeats,
          ticketName: res.data.ticketName,
          taxRatio: res.data.taxRatio,
        });
        setBookedSeats(res.data.bookedSeats);
        setLocation(res.data.address);
        setMarker({ lat: res.data.lat, lng: res.data.lng });
        setLat(res.data.lat);
        setLng(res.data.lng);
        if (res.data.chart_key) {
          setOldChart(res.data.chart_key);
          setOld_seats_io_Map(res.data.seats_io_Map);
          chartKey = res.data.chart_key;
          chartCategories = res.data.seats_io_Map;
        }

      }
    };
    try {
      getEvent();
      fetchCategories();
    }
    catch (err) {
      console.log(err);

    }
    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  const addSlot = () => {
    setEventDetails({
      ...eventDetails,
      timeSlots: [...eventDetails.timeSlots, timeSlot],
    });
    setTimeSlot({
      startTime: "",
      endTime: "",
    });
  };

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const getUploadedFiles = (files) => {
    setFiles(files);
    setEventDetails({
      ...eventDetails,
      imageUrl: files,
    });
  };

  const handleUpdateEvent = async () => {
    if (eventDetails.name === "") {
      setError("Please enter event name");
      return;
    }
    // name_en
    if (eventDetails.name_en === "") {
      setError("Please enter event name in english");
      return;
    }
    if (eventDetails.starName === "") {
      setError("Please enter star name");
      return;
    }
    // starName_en
    if (eventDetails.starName_en === "") {
      setError("Please enter star name in english");
      return;
    }
    if (eventDetails.category === "") {
      setError("Please select category");
      return;
    }
    if (eventDetails.description === "") {
      setError("Please enter description");
      return;
    }
    // description_en
    if (eventDetails.description_en === "") {
      setError("Please enter description in english");
      return;
    }
    if (eventDetails.startDate === "") {
      setError("Please enter start date");
      return;
    }
    if (eventDetails.endDate === "" && eventDetails.recurring === true) {
      setError("Please enter end date");
      return;
    }
    if (eventDetails.imageUrl.length === 0) {
      setError("Please upload image");
      return;
    }

    if (!marker) {
      setError("Please Select location, Please move the pin to set the location");
      return;
    }
    if (eventDetails.timeSlots.length == 0) {
      setError("Please add one time slot at least");
      return;
    }
    if (bookedSeats <= 0) {
      console.log("bookedSeats", bookedSeats, "chartKey", chartKey, oldChart, chartCategories, chartCategories == {}, Object.keys(chartCategories).length, Object.keys(chartCategories).length == 0);
      if (Object.keys(chartCategories).length == 0) {
        alert("Please assign prices seats to venue map");
        chartCategories = old_seats_io_Map;
      }
      if (isVenue) {
        if (
          chartKey == undefined
        ) {
          if (oldChart) chartKey = oldChart;
          else {
            setError("Please Add seats to venue map");
            return;
          }
        }
        if (Object.keys(chartCategories).length == 0) {
          setError("Please assign prices seats to venue map");
          return;
        }
      }

      if (!isVenue) {
        if (
          allSeats.reduce((acc, val) => {
            return acc + val.seatsCount;
          }, 0) === 0
        ) {
          setError("Please Add seats in seats dashboard");
          return;
        }
      }
    }
    //upload images and submit event
    try {
      //uploading images
      setLoading(true);
      const UrlArray = [];
      for (let i = 0; i < files.length; i++) {
        if (typeof files[i] !== 'string') {
          const formData1 = new FormData();
          formData1.append("file", files[i]);
          const res = await axios.post(BASE_SERVER + "/upload/", formData1, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          UrlArray.push(res.data.imageUrl);
        }
      }
      //submit event
      const body = {
        name: eventDetails.name,
        name_en: eventDetails.name_en,
        starName: eventDetails.starName,
        starName_en: eventDetails.starName_en,
        category: eventDetails.category,
        description: eventDetails.description,
        description_en: eventDetails.description_en,
        startDate: eventDetails.startDate,
        endDate: eventDetails.endDate,
        recurring: eventDetails.recurring,
        numberOfSeats: seats.length,
        address: location,
        taxRatio: eventDetails.taxRatio,
        types: types,
        lat: marker.lat,
        lng: marker.lng,
        venueMap: isVenue
          ? {
            seats,
            topTables,
            bottomTables,
            rightTables,
            leftTables,
            rows,
            columns,
            topObjects,
            bottomObjects,
            rightObjects,
            leftObjects,
          }
          : {},
        numberOfSeats: isVenue ? 0 : seatsAvailable,
        seatsTypes: isVenue ? [] : allSeats,
        timeSlots: eventDetails.timeSlots,
        imageUrl: UrlArray,
        numberOfAvailableSeats: seats.filter((seat) => seat.isReserved).length,
        currency: "SAR",
        provider_id: reduxState.auth?.userId,
        provider_name: JSON.parse(reduxState.auth.userData).name || "admin",
        chart_key: chartKey,
        chartCategories: chartCategories
      };
      let eventId = window.location.href.split('/')[window.location.href.split('/').length - 1];
      const res = await axios.put(BASE_SERVER + `/event/updateEvent/booked/${eventId}`, body);
      if (res.status === 200) {
        setLoading(false);
        setIsOpen(false);
        //reset the form
        setEventDetails({
          name: "",
          name_en: "",
          starName: "",
          starName_en: "",
          category: "sport",
          description: "",
          description_en: "",
          startDate: "",
          endDate: "",
          recurring: false,
          numberOfSeats: 0,
          address: "",
          lat: 0,
          lng: 0,
          timeSlots: [],
          imageUrl: [],
          numberOfAvailableSeats: 0,
          currency: "SAR",
          provider_id: "",
          provider_name: "",
          venueMap: {},
          seatsTypes: [],
          numberOfSeats: 0,
          ticketName: "",
          taxRatio: 0,
          chartKey: undefined,
          chartCategories: {}
        });
        setFiles([]);
        setError("");
        //navigate to dashboard
        window.location.href = "/dashboard";
      }
    } catch (err) {
      console.log("err : ", err);
      setLoading(false);

      setError("Something went wrong please try again later or provider is not active");
    }

  };

  return (
    <Flex
      w="90%"
      m="0 auto"
      align="center"
      justify={"center"}
      direction="column"
    >
      <Flex w="100%" direction="row" justify="center" style={
        {
          marginBottom: "30px"
        }
      }>
        <h2><Translate content='update_event' /></h2>
      </Flex>
      <Flex direction={"column"} align="center" className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='event_name' /></label>
        <Translate
          component='input'
          attributes={{ placeholder: 'event_name' }}
          onChange={(e) => {
            setEventDetails({ ...eventDetails, name: e.target.value });
          }}
          value={eventDetails.name}
          className="custom-input"
          type='text' />

      </Flex>
      <Flex direction={"column"} align="center" className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='event_name_en' /></label>
        <Translate
          component='input'
          attributes={{ placeholder: 'event_name_en' }}
          onChange={(e) => {
            setEventDetails({ ...eventDetails, name_en: e.target.value });
          }}
          value={eventDetails.name_en}
          className="custom-input"
          type='text' />
      </Flex>

      <Flex direction={"column"} className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='star_name' /></label>
        <Translate
          component='input'
          attributes={{ placeholder: 'star_name' }}
          onChange={(e) => {
            setEventDetails({ ...eventDetails, starName: e.target.value });
          }}
          value={eventDetails.starName}
          className="custom-input"
          type='text' />
      </Flex>
      <Flex direction={"column"} className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='star_name_en' /></label>
        <Translate
          component='input'
          attributes={{ placeholder: 'star_name_en' }}
          onChange={(e) => {
            setEventDetails({ ...eventDetails, starName_en: e.target.value });
          }}
          value={eventDetails.starName_en}
          className="custom-input"
          type='text' />
      </Flex>

      <Flex direction={"column"} align="center" className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='event_type' /></label>

        <select
          className="custom-input"
          onChange={(e) => {
            setEventDetails({ ...eventDetails, category: e.target.value });
          }}
        >
          {categories?.map((c) => (
            <option key={c} value={c} selected={c === eventDetails.category}>
              {c}
            </option>
          ))}
        </select>
      </Flex>
      <Flex direction={"column"} className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='description_ar' /></label>
        <textarea
          className="custom-input"
          style={{ height: "200px", padding: "10px" }}
          value={eventDetails.description}
          onChange={(e) => {
            setEventDetails({
              ...eventDetails,
              description: e.target.value,
            });
          }}
        />
      </Flex>
      <Flex direction={"column"} className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='description_en' /></label>
        <textarea
          className="custom-input"
          style={{ height: "200px", padding: "10px" }}
          value={eventDetails.description_en}
          onChange={(e) => {
            setEventDetails({
              ...eventDetails,
              description_en: e.target.value,
            });
          }}
        />
      </Flex>
      {!bookedSeats && <Flex direction={"column"} className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='tax_ratio_label' /></label>
        <input
          className="custom-input"
          type="number"
          min={0}
          max={100}
          value={eventDetails.taxRatio}
          onChange={(e) => {
            setEventDetails({
              ...eventDetails,
              taxRatio: Math.max(0, Math.min(e.target.value, 100)),
            });
          }}
        />
      </Flex>}
      {!bookedSeats && <Flex direction={"column"} justify="center" align="center" className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='repeated_event' /></label>
        <input
          className="custom-input"
          type="checkbox"
          checked={eventDetails.recurring}
          onChange={(e) => {
            setEventDetails({
              ...eventDetails,
              recurring: e.target.checked,
            });
          }}
          style={{
            marginLeft: "10px",
            height: "20px",
            width: "20px",
          }}
        />
      </Flex>}
      {!bookedSeats && <Flex direction={"column"} className="form-feild">
        {eventDetails.recurring ? (
          <Flex direction={"column"}>
            <label style={{ fontWeight: "bold" }}> <Translate content='event_start_date' /></label>
            <input
              className="custom-input"
              type="date"
              value={eventDetails.startDate}
              style={{ marginBottom: '16px' }}
              onChange={(e) => {
                setEventDetails({
                  ...eventDetails,
                  startDate: e.target.value,
                });
              }}
            />
            <label style={{ fontWeight: "bold" }}><Translate content='event_end_date' /></label>
            <input
              className="custom-input"
              type="date"
              value={eventDetails.endDate}
              onChange={(e) => {
                setEventDetails({
                  ...eventDetails,
                  endDate: e.target.value,
                });
              }}
            />
          </Flex>
        ) : (
          <Flex direction={"column"}>
            <label style={{ fontWeight: "bold" }}><Translate content='event_start_date' /></label>
            <input
              className="custom-input"
              type="date"
              value={eventDetails.startDate}
              onChange={(e) => {
                setEventDetails({
                  ...eventDetails,
                  startDate: e.target.value,
                });
              }}
            />
          </Flex>
        )}
        <hr className="bg-light mx-3"></hr>

        <div style={{ fontSize: "20px", fontWeight: "400" }}><Translate content='event_times_slots_label' /></div>

        {eventDetails.timeSlots.length > 0 ? (
          <div style={{ fontSize: "18px", fontWeight: "400" }}>
            {eventDetails.timeSlots.map((timeSlot, i) => (
              <div style={{ margin: "8px" }} key={i}>
                <span>{timeSlot.startTime}</span>
                <span><Translate content='to_label' /></span>
                <span>{timeSlot.endTime}</span>
                <Button
                  style={{ margin: "0px 10px" }}
                  variant="outline-secondary"
                  onClick={(e) =>
                    setEventDetails({
                      ...eventDetails,
                      timeSlots: eventDetails.timeSlots.filter(
                        (f) =>
                          timeSlot.startTime !== f.startTime &&
                          timeSlot.endTime !== f.endTime
                      ),
                    })
                  }
                >
                  X
                </Button>
              </div>
            ))}
            <hr className="bg-light mx-3"></hr>
          </div>
        ) : (
          <>
            <div><Translate content='event_times_slots_hint' /></div>
            <hr className="bg-light mx-3"></hr>
          </>
        )}
      </Flex>}
      {!bookedSeats && <Flex direction={"column"} className="form-feild">
        <label style={{ fontWeight: "bold" }}><Translate content='start_time' /></label>
        <input
          className="custom-input"
          type="time"
          style={{ marginBottom: "16px" }}
          value={timeSlot.startTime}
          onChange={(e) => {
            setTimeSlot({ ...timeSlot, startTime: e.target.value });
          }}
        />
        <label style={{ fontWeight: "bold" }}><Translate content='end_time' /></label>
        <input
          className="custom-input"
          type="time"
          value={timeSlot.endTime}
          onChange={(e) => {
            setTimeSlot({ ...timeSlot, endTime: e.target.value });
          }}
        />

        <Button
          className='btn btn-success my-3' style={{ background: 'purple', border: 'purple' }}
          variant="primary"
          onClick={addSlot}
        >
          <Translate content='button_add_event_time' />
        </Button>
        <hr className="bg-light mx-3"></hr>
      </Flex>
      }
      <Flex direction={"column"} align="center">

        <label style={{ fontWeight: "bold" }}><Translate content='event_images_label' /></label>
        <PreviewMultipleImages getFiles={getUploadedFiles} />
      </Flex>

      {!bookedSeats && <Flex direction={"column"}>
        <hr className="bg-light mx-3"></hr>
        <label style={{ fontWeight: "bold" }}><Translate content='event_location_label' /></label>
        <Map
          getLocation={setLocation}
          setMarker={setMarker}
          lat={lat}
          lng={lng}
          style={{ height: "230px", width: "400px", maxWidth: "90%" }}
        />
        <hr className="bg-light mx-3"></hr>

      </Flex>}
      {/* </Modal.Body> */}
      {/* <Modal.Footer> */}
      {!bookedSeats && <Flex direction={"column"} style={{ width: '100%' }}>
        <EventSeatsType chartKey={oldChart} seats_io_Map={old_seats_io_Map} lat={lat} />
      </Flex>}
      {/* <Button variant="secondary" onClick={onClose}>
        Close
      </Button> */}
      <Button
        variant="primary"
        onClick={handleUpdateEvent}
        size="lg"
        className='btn btn-success my-3'
        style={{
          marginBlock: "20px",
          fontSize: "25px",
          background: 'purple',
          border: 'purple'
        }}
      >
        <Translate content='update_event' />
      </Button>
      <div className="text-center">
        <p style={{ color: "red" }}>{error.length > 0 ? error : ""}</p>
      </div>
      <div>
        {loading ? (
          <div class="spinner-border  my-2 text-center" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* </Modal.Footer> */}
      {/* </Modal> */}
    </Flex>
  );
};

export default EventDetails;
