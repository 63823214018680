import React from 'react'
import landing5 from './images/landing5.png'
import landing6 from './images/landing6.png'
import landing7 from './images/landing7.png'
import mada from './images/mada-logo.svg'
import masterCard from './images/mastercard-logo.svg'
import stc from './images/vbm_blk01.png'
import face from './images/face.jpg'
import Slider from "react-slick";

import Fade from 'react-reveal/Fade'


class Screen extends React.Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };
        return (
            <div>
                <div className='row mx-0 py-5 my-5'>
                    <div className='col-lg-6 text-center  mb-5' style={{ direction: 'rtl' }} >

                        <div style={{ maxWidth: '500px', margin: '0 auto' }} >
                            <p style={{ fontSize: '40px' }}>ميزاته لمقدم الفعالية</p>


                            <div className='mt-5'>

                                <p className='text-dark' style={{ fontSize: '20px' }} >طريقة حديثة لتسويق الفعاليات.
                                    و لوحة تحكم ممتازة وشاملة.
                                </p>
                            </div>

                            <div className='mt-5'>

                                <p className='text-dark' style={{ fontSize: '20px' }} >القدرة على إنشاء وإدارة فعالية جديدة على
                                    التطبيق بكل تفاصيلها مثل الموقع والسعر والمقاعد المتاحة ... إلخ.</p>
                            </div>

                            <div className='mt-5'>

                                <p className='text-dark' style={{ fontSize: '20px' }}>	القدرة على نشر الفعالية في أي وقت.
                                    و يجعل الفعالية في متناول العديد من المستخدمين ويوسع قاعدة الجمهور.
                                </p>
                            </div>

                            <div className='mt-5'>

                                <p className='text-dark' style={{ fontSize: '20px' }}>
                                    القدرة على إضافة مستخدمين عاملين جدد للمساعدة في النظام الأساسي (مثل المشرفين الآخرين الذين يمكنهم أيضاً إنشاء وإدارة الأحداث).
                                </p>
                            </div>

                            <div className='mt-5'>

                                <p className='text-dark' style={{ fontSize: '20px' }}>
                                    يوفر عملية مراقبة وإدارة كاملة للفعالية.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 text-center mt-4'>

                        <div className=''>

                            <Fade bottom >
                                <img alt='img' src={landing5} style={{ maxWidth: '45%' }} />
                                <img alt='img' className=' ml-md-5 mt-5' src={landing6} style={{ maxWidth: '40%' }} />
                            </Fade>


                            <Fade top>
                                <img alt='img' src={landing7} style={{ maxWidth: '45%', position: 'absolute', left: '35%', top: '-10%' }} />
                            </Fade>






                        </div>


                    </div>


                </div>
                <div className='text-center mx-auto mt-5 py-5'>
                    <img src={mada} style={{ width: '100px', paddingLeft: '5px' }} alt="طرق الدفع" />
                    <img src={masterCard} style={{ width: '100px', paddingLeft: '5px' }} alt="طرق الدفع" />
                    <img src={stc} style={{ width: '100px', paddingLeft: '5px' }} alt="طرق الدفع" />
                </div>

                <div className='text-center mx-auto mt-5 py-5'>
                    <a style={{ fontSize: '35px' }}>What others are saying</a>

                    <hr style={{ width: '100px', borderTop: '1px solid #ff8197' }} />

                    <Slider {...settings} style={{ width: '900px', maxWidth: '95%', margin: '0 auto' }}>

                        <Fade>


                            <div>

                                <div className='card shadow  mt-5 mx-auto my-3' style={{ borderRadius: '25px', border: 'none', height: '350px', width: '600px', maxWidth: '90%' }}>
                                    <div className=' card-body text-center mx-auto' style={{ height: '350px', width: '600px', maxWidth: '90%' }}>
                                        <div className='mx-auto' style={{ width: '100px', height: '100px', borderRadius: '50%', overflow: 'hidden' }}>
                                            <img alt='img' src={face} width='100' />
                                        </div>

                                        <h5 style={{ fontWeight: '400', paddingTop: '15px' }}>Evan olson</h5>

                                        <hr style={{ width: '400px', maxWidth: '95%' }}></hr>

                                        <p style={{ color: 'gray' }} > Your project looks great on my device. Content can be easily read and a user
                                            understands freely what you wanted to say hem or her.
                                        </p>

                                        <p style={{ color: 'darkgray' }}>3.17 AM - 18 mar 2021</p>
                                    </div>
                                </div>
                            </div>

                        </Fade>

                        <div>

                            <div className='card shadow  mt-5 mx-auto my-3' style={{ borderRadius: '25px', border: 'none', height: '350px', width: '600px', maxWidth: '90%' }}>
                                <div className=' card-body text-center mx-auto' style={{ height: '350px', width: '600px', maxWidth: '90%' }}>
                                    <div className='mx-auto' style={{ width: '100px', height: '100px', borderRadius: '50%', overflow: 'hidden' }}>
                                        <img alt='img' src={face} width='100' />
                                    </div>

                                    <h5 style={{ fontWeight: '400', paddingTop: '15px' }}>Evan olson</h5>

                                    <hr style={{ width: '400px', maxWidth: '95%' }}></hr>

                                    <p style={{ color: 'gray' }} > Your project looks great on my device. Content can be easily read and a user
                                        understands freely what you wanted to say hem or her.
                                    </p>

                                    <p style={{ color: 'darkgray' }}>3.17 AM - 18 mar 2021</p>
                                </div>
                            </div>
                        </div>

                        <div>

                            <div className='card shadow  mt-5 mx-auto' style={{ borderRadius: '25px', border: 'none', height: '350px', width: '600px', maxWidth: '90%' }}>
                                <div className=' card-body text-center mx-auto' style={{ height: '350px', width: '600px', maxWidth: '90%' }}>
                                    <div className='mx-auto' style={{ width: '100px', height: '100px', borderRadius: '50%', overflow: 'hidden' }}>
                                        <img alt='img' src={face} width='100' />
                                    </div>

                                    <h5 style={{ fontWeight: '400', paddingTop: '15px' }}>Evan olson</h5>

                                    <hr style={{ width: '400px', maxWidth: '95%' }}></hr>

                                    <div>
                                        <p style={{ color: 'gray' }} > Your project looks great on my device. Content can be easily read and a user
                                            understands freely what you wanted to say hem or her.
                                        </p>
                                    </div>

                                    <p style={{ color: 'darkgray' }}>3.17 AM - 18 mar 2021</p>
                                </div>
                            </div>
                        </div>


                    </Slider>
                </div>




            </div>
        )
    }
}

export default Screen