import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import Translate from 'react-translate-component'
import store from '../store'

class reports extends React.Component {
    state = {
        filter: 'filter'
    }

    onFilter = (e) => {
        this.setState({
            filter: e.target.name
        })
    }
    getBookingCount(e) {
        if (e.Event.bookedSeatsMap) {
            let bookingString = "";
            for (const key in e.Event.bookedSeatsMap) {
                bookingString = bookingString + `${key} (${e.Event.bookedSeatsMap[key]}). \n`;
            }
            return bookingString;
        }
        else {
            return e.bookingCount;
        }
    }



    render() {

        let { events, topEvents, providers, users } = this.props


        return (

            <div className='reports pb-5 justify-content-center mx-auto text-center pt-5'>

                {/** first reports cards */}
                <div className='row mx-auto justify-content-center ' style={{ direction: 'ltr' }}>
                    <div className='card shadow mx-0 mx-md-3 my-3' style={{ background: 'linear-gradient(to right, rgb(205 41 253), rgb(249 152 255))' }} >
                        <div className='card-body mt-3 ' style={{ width: 'fit-content' }}>
                            <FontAwesomeIcon icon={faCalendarAlt} style={{ fontSize: '30px' }} />
                            <h5 className='mt-3'> <Translate content='total_events' /></h5> <h4 className='mt-3'>
                                {events.length}</h4>
                        </div>
                        <div className='first-circle'></div>
                        <div className='sec-circle'></div>
                    </div>
                    {store.getState().auth.userType === 'admin' || localStorage.getItem('userType') === 'admin' ?
                        <div className='card shadow mr-0 mx-md-3 my-3' style={{ background: 'linear-gradient(to right,  rgb(118 144 255), rgb(137 188 251))' }} >
                            <div className='card-body mt-3' style={{ width: 'fit-content' }}>
                                <FontAwesomeIcon icon={faUser} style={{ fontSize: '30px' }} />
                                <h5 className='mt-3'>
                                    <Translate content='total_providers' /></h5>  <h4 className='mt-3'>{providers.length}</h4>
                            </div>
                            <div className='first-circle'></div>
                            <div className='sec-circle'></div>
                        </div>
                        : null}
                    {store.getState().auth.userType === 'admin' || localStorage.getItem('userType') === 'admin' ?
                        <div className='card shadow mr-0 mx-md-3 my-3' style={{ background: 'linear-gradient(to left, #eedfc3, #ef6d6d)' }} >
                            <div className='card-body mt-3 ' style={{ width: 'fit-content' }}>
                                <FontAwesomeIcon icon={faUsers} style={{ fontSize: '30px' }} />
                                <h5 className='mt-3'>
                                    <Translate content='total_users' /></h5> <h4 className='mt-3'>{users.length}</h4>
                            </div>

                            <div className='first-circle'></div>
                            <div className='sec-circle'></div>
                        </div>
                        : null}
                </div>

                {/** events report */}

                <h4 className='pt-5' style={{ color: 'purple' }} > <Translate content='top_events' /></h4>

                <div className='table-card card shadow mx-auto mt-5 text-center ' style={{ maxWidth: '90%', width: '1800px', minHeight: '400px', color: 'purple', overflow: 'auto' }}>
                    <table className='mt-3' >

                        <thead className='mx-auto ' style={{ borderBottom: '.5px solid #ff5e00 ' }}>
                            <tr>
                                <th > <Translate content='image' /></th>

                                <th > <Translate content='provider_name' /></th>
                                <th > <Translate content='event_name' /></th>
                                <th > <Translate content='event_type' /></th>

                                <th > <Translate content='star_name' /></th>
                                <th > <Translate content='event_date' /></th>

                                <th > <Translate content='seats_count' /></th>



                                <th > <Translate content='booked_seats' /></th>

                            </tr>
                        </thead>

                        <tbody >
                            {topEvents.map((e, key = 0) => (
                                <tr key={key++}>
                                    <td className='py-3'>
                                        <img alt='img' src={e.Event.imageUrl} width='100' height='70' style={{ borderRadius: '18px' }} />
                                    </td>
                                    <td >{e.Event.provider_name}</td>
                                    <td >{e.Event.name}</td>
                                    <td >{e.Event.category}</td>
                                    <td >{e.Event.starName}</td>
                                    <td style={{ width: '150px' }}>{e.Event.date ? e.Event.date.substring(0, 10) : ''}</td>
                                    <td >{e.Event.numberOfSeats}</td>

                                    <td >{this.getBookingCount(e)}</td>



                                </tr>
                            ))}
                        </tbody>



                    </table>

                </div>

            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    events: state.eventState.events,
    topEvents: state.eventState.topEvents,
    providers: state.providerState.providers,
    users: state.userState.users

})
export default connect(mapStateToProps, {})(reports)