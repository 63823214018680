import axios from "axios";

import {
  GET_SINGLE_BOOKING_START,
  GET_SINGLE_BOOKING_FAILED,
  GET_SINGLE_BOOKING_SUCCESS,
} from "./types";
var BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

//get booking

export const getSingleBooking = (id) => (dispatch) => {
  dispatch({ type: GET_SINGLE_BOOKING_START });
  axios
    .get(BASE_SERVER + `/booking/details/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) =>
      dispatch({
        type: GET_SINGLE_BOOKING_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_SINGLE_BOOKING_FAILED });
    });
};
