import {
    GET_USERS,
    DELETE_USER,
    UPDATE_USER,
    GET_USER,
    GET_BOOKING,
    CLEAR_USER

} from '../actions/types'

const initialState = {
    users: [],
    user: '',
    booking: []
}


function Users (state = initialState, action) {
    switch (action.type) {

        case GET_USERS:

            return {
                ...state,
                users: action.payload
            }
            case GET_USER:
             
                return {
                    ...state,
                    user: action.payload
                }    
                case DELETE_USER:

                    return {
                        ...state,
                        users: state.users.filter(user => user._id !== action.payload)  
             }

             case UPDATE_USER:

                return {
                    ...state,
                    users: state.users.map(user => {
                        if (user._id === action.payload._id) {
                            return action.payload
                        } else {
                            return user
                        }
                    })
                }
            case GET_BOOKING: 
                return{
                    ...state,
                    booking: action.payload
                } 

        case CLEAR_USER:
             return{ 
                 ...state, 
                 user: ''
             }        
        
        default:
            return state
    }
}

export default Users;