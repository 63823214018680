import axios from "axios";

import {
  GET_USERS,
  DELETE_USER,
  UPDATE_USER,
  GET_USER,
  GET_BOOKING,
  CLEAR_USER,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
} from "./types";
var BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

//get users
export const getUsers = () => (dispatch) => {
  axios.get(BASE_SERVER + "/auth/users").then((res) =>
    dispatch({
      type: GET_USERS,
      payload: res.data,
    })
  );
};

//get user
export const getUser = (id) => (dispatch) => {
  axios.get(BASE_SERVER + `/auth/user/${id}`).then((res) =>
    dispatch({
      type: GET_USER,
      payload: res.data,
    })
  );
};

//get user
export const loginUser = (data) => (dispatch) => {
  dispatch({ type: LOGIN_USER_START });
  axios
    .post(BASE_SERVER + `/auth/user/login`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) =>
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({
        type: LOGIN_USER_FAILED,
        payload: err.response.data,
      });
    });
};

//update user
export const updateUser = (id, body) => (dispatch) => {
  axios.put(BASE_SERVER + `/auth/user/${id}`, body).then((res) =>
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    })
  );
};

//delete user
export const deleteUser = (id) => (dispatch) => {
  axios.delete(BASE_SERVER + `/auth/user/${id}`).then((res) =>
    dispatch({
      type: DELETE_USER,
      payload: res.data,
    })
  );
};

//get booking
export const getBooking = () => (dispatch) => {
  try {
    axios.get(BASE_SERVER + `/booking`).then((res) =>
      dispatch({
        type: GET_BOOKING,
        payload: res.data,
      })
    );
  } catch (err) {
    console.log(err);
  }
};

//clear users
export const clearUsers = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER,
  });
};
