import axios from "axios";

import {
  GET_EVENT,
  TOP_EVENTS,
  GET_EVENTS,
  POST_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
  PROVIDER_EVENTS,
  ADD_CATEGORY,
  GET_CATEGOIES,
  DELETE_CATEGOIES,
  CLEAR_EVENT,
  ADD_SEAT,
  GET_SEATS,
  DELETE_SEATS,
  BOOKED_EVENTS,
  BOOK_TICKET_SUCCESS,
  BOOK_TICKET_START,
  BOOK_TICKET_FAILED,
} from "./types";
import store from "../store";

var BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

//get events
export const getEvents = () => (dispatch) => {
  axios.get(BASE_SERVER + "/event/all/events?admin=true").then((res) =>
    dispatch({
      type: GET_EVENTS,
      payload: res.data,
    })
  );
};

//get top events
export const getTopEvents = () => (dispatch) => {
  axios.get(BASE_SERVER + "/event/booking/trending").then((res) =>
    dispatch({
      type: TOP_EVENTS,
      payload: res.data,
    })
  );
};

//get event
export const getEvent = (id) => (dispatch) => {
  axios.get(BASE_SERVER + `/event/${id}`).then((res) =>
    dispatch({
      type: GET_EVENT,
      payload: res.data,
    })
  );
};

//get provider events
export const getProviderEvents = (id) => (dispatch) => {
  axios.get(BASE_SERVER + `/event/provider/${id}`).then((res) =>
    dispatch({
      type:
        store.getState().auth.userType === "provider"
          ? GET_EVENTS
          : PROVIDER_EVENTS,
      payload: res.data,
    })
  );
};

export const getProviderTopEvents = (id) => (dispatch) => {
  axios
    .get(BASE_SERVER + `/event/provider/${id}/booking/trending`)
    .then((res) =>
      dispatch({
        type: TOP_EVENTS,
        payload: res.data,
      })
    );
};
// get all booking for one event
export const getEventBookings = (id) => (dispatch) => {
  axios.get(BASE_SERVER + `/booking/event/${id}`).then((res) =>
    dispatch({
      type: BOOKED_EVENTS,
      payload: res.data,
    })
  );
};
//post event
export const postEvent = (body) => (dispatch) => {
  axios.post(BASE_SERVER + `/event/`, body).then((res) =>
    dispatch({
      type: POST_EVENT,
      payload: res.data,
    })
  );
};

//delete event
export const deleteEvent = (id) => (dispatch) => {
  axios.delete(BASE_SERVER + `/event/${id}`).then((res) =>
    dispatch({
      type: DELETE_EVENT,
      payload: res.data,
    })
  );
};

//update event
export const updateEvent = (id, body) => (dispatch) => {
  axios.put(BASE_SERVER + `/event/${id}`, body).then((res) =>
    dispatch({
      type: UPDATE_EVENT,
      payload: res.data,
    })
  );
};

//ADD CATEGORY
export const addCategory = (body) => (dispatch) => {
  axios.post(BASE_SERVER + `/category`, body).then((res) =>
    dispatch({
      type: ADD_CATEGORY,
      payload: res.data,
    })
  );
};

//get categories
export const getCategories = () => (dispatch) => {
  axios.get(BASE_SERVER + `/category`).then((res) =>
    dispatch({
      type: GET_CATEGOIES,
      payload: res.data,
    })
  );
};

//delete categories
export const deleteCategories = (name) => (dispatch) => {
  axios.delete(BASE_SERVER + `/category/${name}`).then((res) =>
    dispatch({
      type: DELETE_CATEGOIES,
      payload: res.data,
    })
  );
};

//ADD seat
export const addSeat = (body) => (dispatch) => {
  body.name = body.name.trim();
  axios.post(BASE_SERVER + `/seats`, body).then((res) =>
    dispatch({
      type: ADD_SEAT,
      payload: res.data,
    })
  );
};

//get categories
export const getSeats = () => (dispatch) => {
  axios.get(BASE_SERVER + `/seats`).then((res) =>
    dispatch({
      type: GET_SEATS,
      payload: res.data,
    })
  );
};

//delete categories
export const deleteSeats = (name) => (dispatch) => {
  axios.delete(BASE_SERVER + `/seats/${name}`).then((res) =>
    dispatch({
      type: DELETE_SEATS,
      payload: res.data,
    })
  );
};

//clear events
export const clearEvent = () => (dispatch) => {
  dispatch({
    type: CLEAR_EVENT,
  });
};

// Needs to be moved to booking actions
export const bookEvent = (body, token) => (dispatch) => {
  dispatch({ type: BOOK_TICKET_START });
  axios
    .post(BASE_SERVER + `/booking/pay`, JSON.stringify(body), {
      headers: {
        "X-Auth-Token": token,
        "Content-Type": "application/json",
      },
    })
    .then((res) =>
      dispatch({
        type: BOOK_TICKET_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({ type: BOOK_TICKET_FAILED });
    });
};
