import axios from "axios";
import { returnErrors } from "./errorActions";
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  EDIT_ADMIN,
  DELETE_ADMIN,
  GET_ADMINS,
} from "./types";

var BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

export const loadUser = () => (dispatch, getState) => {
  const userType = getState().auth.userType;


  // User loading
  dispatch({
    type: USER_LOADING,
  });
  if (userType === "provider") {
    return;
  }
  axios
    .get(BASE_SERVER + "/auth/admin", tokenConfig(getState))
    .then((res) =>
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch(
        returnErrors(err.response?.data, err.response?.status, "auth error")
      );
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// Register User
export const register =
  ({ email, password, type }) =>
    (dispatch) => {
      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // Request body
      const body = JSON.stringify({
        email,
        password,
        type,
      });

      axios
        .post(BASE_SERVER + "/auth/admin/signup", body, config)
        .then((res) =>
          dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
          })
        )
        .catch((err) => {
          dispatch(
            returnErrors(
              err.response.data,
              err.response.status,
              "register failed"
            )
          );
          dispatch({
            type: REGISTER_FAILED,
          });
        });
    };

// Login User
export const login =
  ({ email, password, type }) =>
    (dispatch) => {
      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // Request body

      /**
       * if '/auth/admin/login' admin login
       * if '/auth/login' admin provider
       */

      const body = JSON.stringify({
        email,
        password,
      });
      const endPoint = type === "admin" ? "/auth/admin/login" : "/auth/login";
      axios
        .post(BASE_SERVER + endPoint, body, config)
        .then((res) => {
          dispatch({
            type: LOGIN_SUCCESS,
            userType: type,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch(
            returnErrors(err.response.data, err.response.status, "login failed")
          );
          dispatch({
            type: LOGIN_FAILED,
          });
        });
    };

// Logout User
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

// Setup config/headers and token
export const tokenConfig = (getState) => {
  // Get token from localstorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }

  return config;
};

export const getAdmins = () => (dispatch) => {
  axios.get(BASE_SERVER + "/auth/admins/").then((res) =>
    dispatch({
      type: GET_ADMINS,
      payload: res.data,
    })
  );
};

export const editAdmin = (id, body) => (dispatch) => {
  axios.put(BASE_SERVER + `/auth/admin/${id}`, body).then((res) =>
    dispatch({
      type: EDIT_ADMIN,
      payload: res.data,
    })
  );
};

export const deleteAdmin = (id) => (dispatch) => {
  axios.delete(BASE_SERVER + `/auth/admin/${id}`).then((res) =>
    dispatch({
      type: DELETE_ADMIN,
      payload: res.data,
    })
  );
};
