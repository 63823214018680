import AppContextProvider from "../../context/AppContextProvider";
import { Flex, ThemeProvider } from "@chakra-ui/core";
import store from "../../store";
import EventDetails from "./EventDetails";
import EventSeatsType from "./EventSeatsType";

const Index = () => {
  const state = store.getState();

  return (
    <ThemeProvider>
      <AppContextProvider>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            padding: "0px 35px"
          }}
        >
          <Flex
            align={"center"}
            justify="center"
            direction={["column", "column", "column"]}
            w="100%"
            overflow={"auto"}
            style={{
              color: "purple",
              padding: "24px"
            }}
            className='table-card card shadow mx-auto mt-5 text-center '
          >
            <EventDetails reduxState={state} />
            {/* <Flex direction={"column"}>
              <EventSeatsType />
            </Flex> */}
          </Flex>
        </div>
      </AppContextProvider>
    </ThemeProvider>
  );
};

store.subscribe(Index);

export default Index;
