import React from 'react'
import {Modal} from 'react-bootstrap'
import {  faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {connect} from 'react-redux'
import {updateProvider, deleteProvider} from '../actions/providersActions'
import Translate from 'react-translate-component'
class EditProviderModal extends React.Component{
    state={
        employers: false,
        successmsg: false
    }

    handleHide = () => {
        this.props.handleEditProviderModal()
        this.setState({
            successmsg: false
        })
    }

    onChange = (e) => {
       this.setState({
           [e.target.name] : e.target.value
       })
    }

    onUpdateProvider = (id ,e) => {
    let {name , email, phone} = this.state

    let body = {name, email , phone}

    this.props.updateProvider(id, body)

    this.setState({
        successmsg: 'provider successfully updated'
    })
    }

    onDeleteEmployer = ( id, e) => {
       this.props.deleteProvider(id)
       
      
    
    }

        render(){

        let provider = this.props.providerData.provider

        let employers =   this.props.providerData.employers 

        
        return(
            <div>
                
                <Modal show={this.props.editProviderModal}
                 onHide = { this.handleHide} style={{color: 'purple'}}>
                    <Modal.Header closeButton >
                        <Modal.Title >
                            <Translate content = 'edit_provider'/> 
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className = 'text-center'>
                          
                      <input onChange = {this.onChange} name= 'email' type= 'email' placeholder = 'Enter email' defaultValue = {provider.email} />

                      <input onChange = {this.onChange} name= 'name' type= 'name' placeholder = 'name' defaultValue = {provider.name}/>
                      <input onChange = {this.onChange} name= 'phone'  type= 'phone' placeholder = 'phone' defaultValue= {provider.phone}/>

                       <div>
                           <p className = 'text-success mx-auto'>{this.state.successmsg}</p>
                       </div>
                      <div>
                       <button onClick = {this.onUpdateProvider.bind(this, provider._id)} className ='btn btn-success my-3 px-3' style={{background: 'purple', border: 'purple'}}>update</button>

                       </div>   

                      <div className = 'card mx-auto mb-3' style= {{width: '300px', maxWidth: '90%'}}>
                         <div className = 'card-body'>
                         <p style={{fontSize: '20px'}}>
                             <Translate content= 'employers'/>
                         </p>
                         <hr/>

                         {employers[0] ? (
                           <div>
                            {employers.map((employer, key = 0) => (
                                <div key= {key++} className = 'my-3' id = {employer._id}>
                                <p className = 'my-auto'>{employer.email}</p>

                               {/**
                                *  <button onClick= {this.onEditEmployer.bind(this, employer._id)} className = 'btn btn-light  ml-auto' style={{color: 'purple'}}>
                                    <FontAwesomeIcon icon={faUserEdit}/>
                                </button>
                                */}

                                <button onClick= {this.onDeleteEmployer.bind(this, employer._id)} className = 'btn btn-light  ml-2' style={{color: 'purple'}}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </button>
                             </div>
                            ))}
                           </div>
                         ): <Translate content='no_employers'/>}
                         </div>
                      </div>
                      
                                       
                        </Modal.Body>
                </Modal>
                
            </div>
        )
    }
}

const mapState = (state) => ({
    providerData: state.providerState.providerData
})

export default connect(mapState, {updateProvider, deleteProvider})(EditProviderModal)