import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import RegularSeats from "./RegularSeats";
import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/core";
import AppContext from "../../context/appContext";
import Translate from 'react-translate-component';
import { Dropdown } from "react-bootstrap";

import { useContext } from "react";
import counterpart from "counterpart";
import { SeatsioDesigner } from '@seatsio/seatsio-react';
import ChartCategoryModal from "./ChartCategoryModal";
import { SeatsioClient, Region, ChartListParams } from 'seatsio';
const BASE_SERVER = process.env.REACT_APP_BASE_SERVER;

const radios = [
  { name: counterpart.getLocale() == 'english' ? "Custom Venu Map" : "رسم القاعة يدويا", value: "1" },
  { name: counterpart.getLocale() == 'english' ? "Regular Seats" : "مقاعد عادية", value: "2" },
  { name: counterpart.getLocale() == 'english' ? "Saved Venu Map" : "قاعة مرسومة سابقا", value: "3" },
];
var prevProps = undefined;
function EventSeatsType(props) {
  const [radioValue, setRadioValue] = useState("1");
  const [categoryModal, setCategoryModal] = useState(false);
  const { setIsVenue, setChartKey, chartKey, setChartCategories, chartCategories } = useContext(AppContext);
  const [chartToUse, setChartToUse] = useState();
  const [IsOldChart, setIsOldChart] = useState(true);
  const [chartsList, setChartsList] = useState([]);
  const [index, setIndex] = useState(-1);
  useEffect(async () => {
    if (radioValue === "1") {
      setIsVenue(true);
    } else {
      setIsVenue(false);
    }
    if (radioValue === "3") {
      setIsOldChart(true);
      setIsVenue(true);

      // clear chartsList
      setChartsList([]);
      let client = new SeatsioClient(Region.EU(), "33a07937-130e-431d-bc94-2ecb093d76d8");
      // check if local storage has userType
      let userType = localStorage.getItem('userType') ? localStorage.getItem('userType') : 'admin';
      let query = {};
      if (userType !== 'admin') {
        const userData = (JSON.parse(localStorage.getItem('userData')))
        // check if user is provider or employee
        if (userData?.type && userData?.type === 'employer') {
          query.providerId = userData?.provider_id;
        }
        else {
          query.providerId = userData?._id;
        }
      }
      query.type = userType;
      let url = `${BASE_SERVER}/map?providerId=${query.providerId}&type=${query.type}`;
      let response = await fetch(url);
      if (response.status === 200) {
        let data = await response.json();
        // set chartsList
        setChartsList(data[0]?.maps);
        // SET INDEX DEPENDING ON CHART KEY
        if (chartKey) {
          let index = data[0]?.maps.findIndex((map) => map.chartKey === chartKey);
          console.log('chartKey', chartKey, 'index', index, 'data', data[0]?.maps);
          setIndex(index);
        }

      }
      // setChartsList(chartsList);
      // console.log('chartsList', chartsList);
      // load charts 
    }
  }, [radioValue]);
  const onChartCreated = (chartKey) => setChartKey(chartKey);
  const onClickNewCategory = (e) => {
    setCategoryModal(!categoryModal);
  };
  const handleCategoryModalUpdate = (state) => {
    setCategoryModal(state);
  };
  const handleCategoryModalSubmit = (categories) => setChartCategories(categories);
  const [priceMap, setPriceMap] = useState({});
  useEffect(() => {
    console.log('props', props);
    if (props?.chartKey && JSON.stringify(props) !== prevProps) {
      prevProps = JSON.stringify(props);
      setChartKey(props?.chartKey);
      setIsOldChart(true);
      setIsVenue(true);
      setChartToUse({ chartKey: props?.chartKey, seats_io_Map: props?.seats_io_Map });
      setRadioValue("3");
      // get chart index form chartsList


      // setPriceMap(props?.seats_io_Map);
    }

    // Cleanup function for componentWillUnmount (if needed)
    return () => {
      // Code to run when the component is unmounted
    };
  }, [props]); // Run the effect whenever lat or lng props change


  // Rest of your component code

  useEffect(() => {
    if (chartToUse) {
      // Only run the following code if chartToUse is truthy
      console.log('Chart to use:', chartToUse);
    }
  }, [chartToUse]);
  useEffect(() => {
    if (setChartKey) {
      // Only run the following code if chartToUse is truthy
      console.log('Chart to use:', setChartKey);
    }
  }, [setChartKey]);
  useEffect(() => {
    if (index) {
      // Only run the following code if chartToUse is truthy
      console.log('Chart to use:', index);
    }
  }, [index]);


  return (
    <>
      <Flex direction="column" my={8} align="center" justify={"center"}>
        <h4><Translate content='event_venue_type' /></h4>
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={
                radioValue === radio.value ? "outline-success" : "outline-dark"
              }
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              style={{ margin: '0px 6px' }}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Flex>



      {radioValue === "1" && (
        <Flex direction={"column"} align="center" justify={"center"} mb="5" style={{ width: '100%', height: '1000px' }}>
          <SeatsioDesigner
            secretKey="33a07937-130e-431d-bc94-2ecb093d76d8"
            chartKey={chartKey}
            region="eu"
            onChartCreated={(chartKey) => {
              onChartCreated(chartKey);
            }}
          />
          <br />
          <br />
          <button
            onClick={(e) => {
              onClickNewCategory(e);
            }}
            className="btn btn-light mx-3 my-2 "
            style={{ color: "purple", background: "#80008017" }}
          >
            <Translate content="manage_chart_categories" />
          </button>
          <ChartCategoryModal
            categoryModal={categoryModal}
            chartKey={chartKey}
            handleCategoryModal={(state) => {
              handleCategoryModalUpdate(state);
            }}
            onSubmit={(categories) => {
              handleCategoryModalSubmit(categories);
            }}
          />
        </Flex>
      )}{radioValue === "2" && (
        <RegularSeats />
      )}
      {radioValue === "3" && (
        <div>
          <label classNmae="px-3">
            <Translate content="chartName" />{" "}
          </label>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic2"
              style={{ overflow: "hiddin" }}
              className="custom-input"
            >
              {chartToUse?.name || <Translate content="select_chart" />}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {chartsList?.length &&
                chartsList?.map((chart, key = 0) => (
                  <Dropdown.Item
                    onClick={() => {
                      setChartToUse(chart);
                      setChartKey(chart.chartKey);
                      setIndex(key);
                    }}
                    key={key++}
                  >
                    {chart.mapName}
                  </Dropdown.Item>
                ))}

            </Dropdown.Menu>
          </Dropdown>
          {chartToUse && (<Flex direction={"column"} align="center" justify={"center"} mb="5" style={{ width: '100%', height: '1000px' }}>
            <SeatsioDesigner
              secretKey="33a07937-130e-431d-bc94-2ecb093d76d8"
              chartKey={chartToUse?.chartKey}
              region="eu"
            />
            <br />
            <br />
            <button
              onClick={(e) => {
                onClickNewCategory(e);
              }}
              className="btn btn-light mx-3 my-2 "
              style={{ color: "purple", background: "#80008017" }}
            >
              <Translate content="manage_chart_categories" />
            </button>
            <ChartCategoryModal
              categoryModal={categoryModal}
              chartKey={chartToUse.chartKey}
              priceMap={chartToUse?.seats_io_Map || {}}
              handleCategoryModal={(state) => {
                handleCategoryModalUpdate(state);
              }}
              onSubmit={(categories) => {
                console.log('categories', categories);
                handleCategoryModalSubmit(categories);
                setChartToUse({ ...chartToUse, seats_io_Map: categories });
              }}
            />
          </Flex>)}
        </div>
      )
      }
    </>
  );
}

export default EventSeatsType;
