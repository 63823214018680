import React from 'react'
import { faBan, faCheckCircle, faUserEdit, faFilter, faTrash, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserModal from  './editUserModal'
import {connect} from 'react-redux'
import {updateUser, deleteUser, getUser, clearUsers} from '../actions/usersActions'
import Translate from 'react-translate-component'
class Users extends React.Component{
    state= {
        eventActive: false,
        userModal: false,
      
      }

      onChange = (e) => {

        let {users, bookings} = this.props
        
        let user = users.filter(u => {
          if(e.target.name === 'name' && u.name){

            return u.name.indexOf(e.target.value) !== -1

          }else if(e.target.name === 'date' && u.date){
             
                  return u.date.indexOf(e.target.value) !== -1
              
            }else if(e.target.name === 'event_name'){
               
                let eventBookings = bookings.filter(book => book.event ? book.event.name.indexOf(e.target.value) !== -1 : '')
                 
                let usersId = eventBookings.map(e => e.booking.user_id)
                  
              return usersId.indexOf(u._id) !== -1
                
                 
                
            }
        })

         
      
        if(e.target.name != ''){
          this.setState({
              users: user
          })
        }else{
          this.setState({
              users: this.props.users
          })
        }
       
      }
  
      onDeactivate = (id, active ,e) => {
        this.props.updateUser(id , {active : !active})
      }

    onClickEditUser = (id, e) => {
        this.props.getUser(id)

        this.setState({
            editUserModal : !this.state.editUserModal
        })
    }


      handleeditUserModal = (e) => {
          this.props.clearUsers()
        this.setState({
            editUserModal: false
        })
    }

    onDeleteUser =(id, e) => {
        this.props.deleteUser(id)
    }

    render(){

        let  bookings = this.props.bookings
        let users = this.state.users || this.props.users


        const getBooking = (id) => {
         let userBooking =   bookings.filter(books => {
            return  books.booking.user_id === id
         })
         return userBooking.length
        }
     
        return(
            <div className='reports justify-content-center mx-auto text-center   pt-5' style={{ width: '80%' }}>

                          {/** filter */}

                <div className='filter-row row mx-auto justify-content-center'>


                    <Translate component='input' attributes= {{placeholder: 'user'}} onChange = {this.onChange} placeholder='user ' type='text' name='name' />
                    
                    <input onChange = {this.onChange} type='date' name='date' />
                    <Translate component='input' attributes= {{placeholder: 'event_name'}} onChange = {this.onChange} placeholder='Event Name' type='text' name='event_name' />


                    <button className='btn btn-lg btn-success my-auto mx-5' style={{ height: '50px', background: 'purple', border: 'purple' }}
                    ><FontAwesomeIcon className='mr-2' icon={faFilter} style={{ fontSize: '20px' }} /><Translate content = 'filter'/></button>
                </div>



                     {/** edit user model */}


                   <UserModal editUserModal = {this.state.editUserModal}
                                handleeditUserModal = {this.handleeditUserModal}/>
        
                     {/** providers */}
                <div className='card shadow mx-auto text-center ' style={{ marginTop: '150px', maxWidth: '90%', width: '1300px', height: '400px', color: 'purple', overflow: 'auto' }}>
                    <table  >

                        <thead className='mx-auto ' style={{ borderBottom: '.5px solid #ff5e00 ' }}>
                            <tr>
                                <th ><Translate content = 'user'/></th>

                                <th ><Translate content = 'user_name'/></th>
                                <th ><Translate content = 'email'/></th>
                                <th ><Translate content = 'phone'/></th>
                                <th><Translate content = 'join_date'/></th>
                                <th ><Translate content = 'booked_events'/></th>

                            </tr>
                        </thead>

                        <tbody >
                            {users.map((user, key = 0) => (
                                <tr key = {key++}>
                                <td >
               {user.imageUrl ? 
                <img className = 'my-2' src = {user.imageUrl} width = '100' style= {{height: '70px', borderRadius: '18px'}}/> : 
                <FontAwesomeIcon className = 'py-2 my-2' icon = {faUser} style = {{width: '100px', height: '70px' ,fontSize: '50px',
                 border: '1px solid #7400cc57' , borderRadius: '18px', color: '#7400cc57'}}/>}
            </td>
    
                                    <td >{user.name}</td>
                                    <td >{user.email}</td>
                                    <td >{user.phone}</td>
                                    <td >{user.date}</td>
                                    <td >{getBooking(user._id)}</td>
    
                                    <td > <button onClick ={this.onClickEditUser.bind(this, user._id)} id='btn-light'><FontAwesomeIcon icon={faUserEdit} /></button></td>
    
                                    <td > <button onClick={this.onDeactivate.bind(this, user._id, user.active)} id='btn-light'><FontAwesomeIcon icon={user.active ? faCheckCircle : faBan} /></button></td>
    
                                    <td > <button onClick = {this.onDeleteUser.bind(this, user._id)} id='btn-light'><FontAwesomeIcon icon={faTrash} /></button></td>
                                </tr>
                            ))}
                        </tbody>



                    </table>

                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    users: state.userState.users,
    bookings: state.userState.booking
})

export default connect(mapState, {updateUser, deleteUser, getUser, clearUsers}) (Users)