/* eslint-disable import/no-anonymous-default-export */
import {
  GET_EVENT,
  TOP_EVENTS,
  GET_EVENTS,
  POST_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
  PROVIDER_EVENTS,
  ADD_CATEGORY,
  GET_CATEGOIES,
  DELETE_CATEGOIES,
  ADD_SEAT,
  GET_SEATS,
  DELETE_SEATS,
  CLEAR_EVENT,
  BOOKED_EVENTS,
  // event booking related imports
  BOOK_TICKET_START,
  BOOK_TICKET_SUCCESS,
  BOOK_TICKET_FAILED,
} from '../actions/types';

const initialState = {
  events: [],
  topEvents: [],
  event: '',
  providerEvents: [],
  categories: [],
  seats: [],
  attendTickets: [],
  bookedEvent: null,
  bookingLoading: false,
  booking: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };

    case GET_EVENT:
      return {
        ...state,
        event: action.payload,
      };

    case UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map((event) => {
          if (event._id === action.payload._id) {
            return action.payload;
          } else {
            return event;
          }
        }),
        providerEvents: state.providerEvents.map((event) => {
          if (event._id === action.payload._id) {
            return action.payload;
          } else {
            return event;
          }
        }),
      };

    case TOP_EVENTS:

      return {
        ...state,
        topEvents: action.payload,
      };
    case PROVIDER_EVENTS:
      return {
        ...state,
        providerEvents: action.payload,
      };
    case POST_EVENT:
      return {
        ...state,
        events: [action.payload, ...state.events],
      };

    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((e) => e._id !== action.payload),
        providerEvents: state.providerEvents.filter((e) => e._id !== action.payload),
      };

    case ADD_CATEGORY:
      return {
        ...state,
        categories: [action.payload, ...state.categories],
      };

    case GET_CATEGOIES:
      return {
        ...state,
        categories: action.payload.categories,
      };

    case DELETE_CATEGOIES:
      return {
        ...state,
        categories: state.categories.filter((type) => type !== action.payload),
      };

    case CLEAR_EVENT:
      return {
        ...state,
        event: '',
      };

    case ADD_SEAT:
      return {
        ...state,
        seats: [action.payload, ...state.seats],
      };

    case GET_SEATS:
      return {
        ...state,
        seats: action.payload.seats,
      };

    case DELETE_SEATS:
      return {
        ...state,
        seats: state.seats.filter((type) => type !== action.payload),
      };
    case BOOKED_EVENTS:
      return {
        ...state,
        attendTickets: action.payload
      };
    // Booking retalted actions Needs to be moved to booking reducer
    case BOOK_TICKET_START:
      return {
        ...state,
        bookingLoading: true
      }
    case BOOK_TICKET_SUCCESS:
      return {
        ...state,
        bookingLoading: false,
        bookedEvent: action.payload,
      }
    case BOOK_TICKET_FAILED:
      return {
        ...state,
        bookingLoading: false,
      }
    // **********//
    default:
      return state;
  }
}