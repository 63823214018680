import React from 'react'
import { Modal } from 'react-bootstrap'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { addCategory, deleteCategories } from '../actions/eventsActions'
import Translate from 'react-translate-component'
class CategoryModal extends React.Component {

    state = {}

    onChange = (e) => {
        if (e.target.value.includes('-')) {
            e.target.value = e.target.value.replace('-', '+')
        }
        this.setState({
            name: e.target.value
        })
    }
    onChangeEn = (e) => {
        if (e.target.value.includes('-')) {
            e.target.value = e.target.value.replace('-', '+')
        }
        this.setState({
            nameEn: e.target.value
        })
    }

    onSubmit = (e) => {
        let name = this.state.name + ' - ' + this.state.nameEn

        this.props.addCategory({ name })
    }

    onDeleteCategory = (type, e) => {
        this.props.deleteCategories(type)
    }


    render() {

        let categories = this.props.categories
        return (
            <div>

                <Modal show={this.props.categoryModal} onHide={this.props.handleCategoryModal} style={{ color: 'purple' }}>
                    <Modal.Header closeButton >
                        <Modal.Title >
                            <Translate content='add_new_category' />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>

                        <Translate component='input' attributes={{ placeholder: 'category_name_ar' }} onChange={this.onChange} type='text' style={{ height: '40px' }} />
                        <Translate component='input' attributes={{ placeholder: 'category_name_en' }} onChange={this.onChangeEn} type='text' style={{ height: '40px' }} />
                        <br />
                        <button onClick={this.onSubmit} type='submit' className='btn btn-success ml-2 mb-1 ' style={{ background: 'purple', border: 'purple' }}>
                            <Translate content='submit' />
                        </button>

                    </Modal.Body>

                    <hr className='mx-3' style={{ color: 'purple' }} />

                    <ul className='text-center' style={{ width: '80%', listStyle: 'none' }}>

                        {categories.map((type, key = 0) => (
                            <li key={key++}><label>{type}</label> <br />
                                <button onClick={this.onDeleteCategory.bind(this, type)} className='btn btn-light ml-5 mb-1' style={{ color: 'purple' }}>
                                    <FontAwesomeIcon icon={faTrash} /></button></li>
                        ))}

                    </ul>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categories: state.eventState.categories
})

export default connect(mapStateToProps, { addCategory, deleteCategories })(CategoryModal)