import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addProvider } from '../actions/providersActions'
import { clearErrors } from '../actions/errorActions'
import Translate from 'react-translate-component'
import axios from 'axios'

class ProviderModal extends React.Component {

    state = {}

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    onSubmit = async (e) => {
        let { imageFile } = this.state;
        let imageUrl;
        if (imageFile) {
            const formData1 = new FormData();
            formData1.append('file', imageFile);
            let res = await axios.post(process.env.REACT_APP_BASE_SERVER + `/upload/`, formData1, { headers: { 'Content-Type': 'multipart/form-data' } })
            imageUrl = res.data.imageUrl;
            this.props.clearErrors()
        }
        this.setState({ successmsg: false })

        let { name, email, phone, password } = this.state

        let body = {
            name, email, phone, password, imageUrl
        }

        await this.props.addProvider(body)
        if (this.props.error.id !== 'add provider error') {
            this.setState({
                successmsg: 'provider successfully addedd'
            })
        }

    }



    render() {
        const onChangeCustom = (e) => {
            const element = document.getElementById('customFile')
            imageIsLoaded1(element.files[0])

        }
        const imageIsLoaded1 = (file) => {
            this.setState({

                imageFile: file
            })

        }

        console.log(this.state)

        return (
            <div>
                <Modal show={this.props.providerModal} onHide={this.props.handleProviderModal} style={{ color: 'purple' }}>
                    <Modal.Header closeButton >
                        <Modal.Title ><Translate content='add_new_provider' /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <Translate component='input' attributes={{ placeholder: 'name' }} onChange={this.onChange} type='name' name='name' />
                        <Translate component='input' attributes={{ placeholder: 'email' }} onChange={this.onChange} type='email' placeholder='Enter email' name='email' />
                        <Translate component='input' attributes={{ placeholder: 'phone' }} onChange={this.onChange} type='number' placeholder='Enter phone' name='phone' />
                        <Translate component='input' attributes={{ placeholder: 'password' }} onChange={this.onChange} type='password' placeholder='Enter password' name='password' />
                        <div>
                            <p className='mx-auto'
                                style={{ color: 'red' }}>{
                                    this.props.error.id === 'add provider error' ? this.props.error.msg.msg : ''}</p>
                        </div>
                        <div>
                            <p className='mx-auto text-success'
                            >{this.state.successmsg ? this.state.successmsg : ''}</p>
                        </div>
                        <input onChange={onChangeCustom} type="file" class="custom-file-input" id="customFile" name="filename" style={{ height: '30px', opacity: 1 }} />
                        <div>
                            <button onClick={this.onSubmit} className='btn btn-success my-3' style={{ background: 'purple', border: 'purple' }}><Translate content='submit' /></button>

                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

const mapState = (state) => ({
    error: state.error
})

export default connect(mapState, { addProvider, clearErrors })(ProviderModal)