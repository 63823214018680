import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUsers,
  faCalendarAlt,
  faUser,
  faChartBar,
  faUserCircle,
  faSignOutAlt,
  faPlus,
  faBars,
  faDollarSign,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";

import Reports from "./reports";
import Events from "./events";
import Providers from "./providers";
import Users from "./users";
import { connect } from "react-redux";
import { getAdmins, loadUser, logout } from "../actions/authActions";
import AdminModal from "./adminModal";
import Admins from "./admins";
import store from "../store";

import NewEvent from "./NewEventPage";

import Translate from "react-translate-component";

import { Dropdown } from "react-bootstrap";
import {
  getCategories,
  getEvents,
  getProviderEvents,
  getProviderTopEvents,
  getSeats,
  getTopEvents,
} from "../actions/eventsActions";
import {
  getEmployers,
  getProvider,
  getProviderEmployers,
  getProviders,
} from "../actions/providersActions";
import {
  getPromocodes,
  getProviderPromocodes,
} from "../actions/promocodeActions";
import { getBooking, getUsers } from "../actions/usersActions";
import Promocodes from "./Promocodes";
class dashboard extends React.Component {
  componentDidMount() {
    const userType =
      store.getState().auth.userType || localStorage.getItem("userType");
    if (
      userType === "provider" &&
      store.getState().auth &&
      store.getState().auth.userId
    ) {
      const providerId = store.getState().auth.userId;
      store.dispatch(loadUser());
      store.dispatch(getSeats());
      store.dispatch(getCategories());
      store.dispatch(getProvider(providerId));
      store.dispatch(getProviderEvents(providerId));
      store.dispatch(getProviderTopEvents(providerId));
      store.dispatch(getProviderEmployers(providerId));
      store.dispatch(getProviderPromocodes(providerId));
    } else {
      store.dispatch(getPromocodes());
      store.dispatch(loadUser());
      store.dispatch(getEvents());
      store.dispatch(getTopEvents());
      store.dispatch(getCategories());
      store.dispatch(getSeats());
      store.dispatch(getProviders());
      store.dispatch(getEmployers());
      store.dispatch(getUsers());
      store.dispatch(getBooking());
      store.dispatch(getAdmins());
    }
    setTimeout(() => {
      if (!this.props.auth.isLoading && !this.props.auth.user) {
        window.location.href = "./login";
      }
    }, 500);
  }

  state = {
    language: "english",
    dashboard: "reports",
    adminModal: false,
  };

  onClickLang = (e) => {
    this.setState({
      language: e.target.name,
    });

    if (e.target.name !== undefined) {
      localStorage.setItem("language", e.target.name);
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  onSideNave = (e) => {
    this.setState({
      dashboard: e.target.name,
    });
  };

  onLoad = (e) => {
    if (!this.props.admin) {
      window.location.href = "/login";
    }
  };

  onlogout = (e) => {
    this.props.logout();
    window.location.href = "./login";
  };

  onAddAdmin = () => {
    this.setState({
      adminModal: !this.state.adminModal,
    });
  };

  handleAdminModal = () => {
    this.setState({
      adminModal: false,
    });
  };

  onShowHide = (e) => {
    this.setState({ sideNav: true });
  };

  onClickSideNav1 = (e) => {
    this.setState({ sideNav: false });
  };

  render() {
    return (
      <div
        className="dashboard d-flex"
        style={{
          overflowY: "auto",
          direction: localStorage.language === "arabic" ? "rtl" : "ltr",
        }}
      >
        <div
          id="sideNav"
          style={{
            position: "absolute",
            zIndex: "3",
            maxWidth: "90%",
            height: "100%",
          }}
        >
          {!this.state.sideNav ? (
            <button
              className="btn btn-light text-dark"
              id="showHide"
              onClick={this.onShowHide}
              style={{ height: "100%", background: "#80008024" }}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          ) : (
            <div
              className="sideNav shadow "
              style={{
                borderTopRightRadius:
                  localStorage.language === "arabic" ? 0 : "25px",
                borderTopLeftRadius:
                  localStorage.language === "arabic" ? "25px" : 0,
                height: "auto",
              }}
            >
              {/**side nav */}
              <div onClick={this.onClickSideNav1}>
                <h4
                  className="pt-4 text-center pb-3"
                  style={{ fontWeight: "bold" }}
                >
                  Tone
                </h4>
                <hr className="bg-light mx-3"></hr>

                <div className="d-flex justify-content-center ">
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{ fontSize: "40px", marginRight: "20px" }}
                  />
                  <h5
                    style={{ marginRight: "20px" }}
                    className="text-center my-auto"
                  >
                    {" "}
                    <span id="nav-item">
                      {store.getState().auth.userType === "admin" ||
                      localStorage.getItem("userType") === "admin"
                        ? "Tone Admin"
                        : JSON.parse(store.getState().auth.userData).name}
                    </span>
                  </h5>
                </div>

                <hr className="bg-light mx-3"></hr>

                <div className="mx-auto" style={{ width: "fit-content" }}>
                  <div className="d-flex">
                    <FontAwesomeIcon
                      className="my-auto"
                      icon={faChartBar}
                      style={{ fontSize: "30px", marginRight: "20px" }}
                    />
                    <Translate
                      style={{ height: "60px", marginRight: "20px" }}
                      className="btn btn-light p-0 my-3 my-md-0"
                      id="btn-light"
                      component="button"
                      content="dashboard"
                      onClick={this.onSideNave}
                      name="reports"
                    />
                  </div>

                  <div className="d-flex">
                    <FontAwesomeIcon
                      className="my-auto"
                      icon={faCalendarAlt}
                      style={{ fontSize: "30px", marginRight: "20px" }}
                    />
                    <Translate
                      style={{ height: "60px", marginRight: "20px" }}
                      className="btn btn-light p-0 my-3 my-md-0"
                      id="btn-light"
                      component="button"
                      content="events"
                      onClick={this.onSideNave}
                      name="events"
                    />
                  </div>

                  {store.getState().auth.userType === "admin" ||
                  localStorage.getItem("userType") === "admin" ? (
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="my-auto"
                        icon={faUser}
                        style={{ fontSize: "30px", marginRight: "20px" }}
                      />
                      <Translate
                        style={{ height: "60px", marginRight: "20px" }}
                        className="btn btn-light p-0 my-3 my-md-0"
                        id="btn-light"
                        component="button"
                        content="providers"
                        onClick={this.onSideNave}
                        name="providers"
                      />
                    </div>
                  ) : null}
                  {store.getState().auth.userType === "admin" ||
                  localStorage.getItem("userType") === "admin" ? (
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="my-auto"
                        icon={faUsers}
                        style={{ fontSize: "30px", marginRight: "20px" }}
                      />
                      <Translate
                        style={{ height: "60px", marginRight: "20px" }}
                        className="btn btn-light p-0 my-3 my-md-0"
                        id="btn-light"
                        component="button"
                        content="users"
                        onClick={this.onSideNave}
                        name="users"
                      />
                    </div>
                  ) : null}
                </div>

                {store.getState().auth.userType === "admin" ||
                localStorage.getItem("userType") === "admin" ? (
                  <div>
                    <hr className="bg-light mx-3"></hr>
                    <div className="mx-auto" style={{ width: "fit-content" }}>
                      <div className="d-flex">
                        <FontAwesomeIcon
                          className="my-auto"
                          icon={faUsers}
                          style={{ fontSize: "30px", marginRight: "20px" }}
                        />
                        <Translate
                          style={{ height: "60px", marginRight: "20px" }}
                          className="btn btn-light p-0 my-3 my-md-0"
                          id="btn-light"
                          component="button"
                          content="admins"
                          onClick={this.onSideNave}
                          name="admins"
                        />
                      </div>
                      <button
                        onClick={this.onAddAdmin}
                        className="btn btn-light p-0 my-3 my-md-0"
                        style={{ fontSize: "23px" }}
                        id="btn-light"
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ fontSize: "20px", marginRight: "18px" }}
                        />
                        <Translate
                          style={{ marginRight: "20px" }}
                          content="add_admin"
                        />
                      </button>{" "}
                    </div>
                  </div>
                ) : null}
                <hr className="bg-light mx-3"></hr>
              </div>

              <Dropdown className="" onClick={this.onClickDropDown}>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic2"
                  style={{
                    background: "none",
                    border: "none",
                    color: "purple",
                    fontSize: "20px",
                  }}
                >
                  <Translate content="language" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="text-center">
                  <Dropdown.Item
                    onClick={this.onClickLang}
                    name="english"
                    style={{ color: "purple" }}
                  >
                    <Translate
                      content="english"
                      component="a"
                      onClick={this.onClickLang}
                      name="english"
                    />{" "}
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={this.onClickLang}
                    name="arabic"
                    style={{ color: "purple" }}
                  >
                    <Translate
                      content="arabic"
                      component="a"
                      onClick={this.onClickLang}
                      name="arabic"
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <hr className="bg-light mx-3"></hr>

              <div className="mx-auto" style={{ width: "fit-content" }}>
                <button
                  onClick={this.onlogout}
                  className="btn btn-light p-0 my-3 my-md-0"
                  style={{ fontSize: "23px" }}
                  id="btn-light"
                >
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    style={{ fontSize: "25px", marginRight: "18px" }}
                  />
                  <Translate style={{ marginRight: "20px" }} content="logout" />
                </button>
              </div>
            </div>
          )}
        </div>

        <div
          className="sideNav shadow "
          id="sideNav2"
          style={{
            borderTopRightRadius:
              localStorage.language === "arabic" ? 0 : "25px",
            borderTopLeftRadius:
              localStorage.language === "arabic" ? "25px" : 0,
          }}
        >
          {/**side nav */}
          <div>
            <h4
              className="pt-4 text-center pb-3"
              style={{ fontWeight: "bold" }}
            >
              Tone
            </h4>
            <hr className="bg-light mx-3"></hr>

            <div className="d-flex justify-content-center ">
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ fontSize: "40px", marginRight: "20px" }}
              />
              <h5
                className="text-center my-auto"
                style={{ marginRight: "20px" }}
              >
                {" "}
                <span id="nav-item">
                  {store.getState().auth.userType === "admin" ||
                  localStorage.getItem("userType") === "admin"
                    ? "Tone Admin"
                    : JSON.parse(store.getState()?.auth?.userData)?.name}
                </span>
              </h5>
            </div>

            <hr className="bg-light mx-3"></hr>

            <div className="mx-auto" style={{ width: "fit-content" }}>
              <div className="d-flex">
                <FontAwesomeIcon
                  className="my-auto"
                  icon={faCalendarCheck}
                  style={{ fontSize: "30px", marginRight: "20px" }}
                />
                <Translate
                  style={{ height: "60px", marginRight: "20px" }}
                  className="btn btn-light p-0 my-3 my-md-0"
                  id="btn-light"
                  component="button"
                  content="new_event"
                  onClick={this.onSideNave}
                  name="newEvent"
                />
              </div>

              <div className="d-flex">
                <FontAwesomeIcon
                  className="my-auto"
                  icon={faChartBar}
                  style={{ fontSize: "30px", marginRight: "20px" }}
                />
                <Translate
                  style={{ height: "60px", marginRight: "20px" }}
                  className="btn btn-light p-0 my-3 my-md-0"
                  id="btn-light"
                  component="button"
                  content="dashboard"
                  onClick={this.onSideNave}
                  name="reports"
                />
              </div>

              <div className="d-flex">
                <FontAwesomeIcon
                  className="my-auto"
                  icon={faCalendarAlt}
                  style={{ fontSize: "30px", marginRight: "20px" }}
                />
                <Translate
                  style={{ height: "60px", marginRight: "20px" }}
                  className="btn btn-light p-0 my-3 my-md-0"
                  id="btn-light"
                  component="button"
                  content="events"
                  onClick={this.onSideNave}
                  name="events"
                />
              </div>
              {/*  */}
              {store.getState().auth.userType === "admin" ||
              localStorage.getItem("userType") === "admin" ? (
                <div className="d-flex">
                  <FontAwesomeIcon
                    className="my-auto"
                    icon={faUser}
                    style={{ fontSize: "30px", marginRight: "20px" }}
                  />
                  <Translate
                    style={{ height: "60px", marginRight: "20px" }}
                    className="btn btn-light p-0 my-3 my-md-0"
                    id="btn-light"
                    component="button"
                    content="providers"
                    onClick={this.onSideNave}
                    name="providers"
                  />
                </div>
              ) : null}

              <div className="d-flex">
                <FontAwesomeIcon
                  className="my-auto"
                  icon={faDollarSign}
                  style={{ fontSize: "30px", marginRight: "20px" }}
                />
                <Translate
                  style={{ height: "60px", marginRight: "20px" }}
                  className="btn btn-light p-0 my-3 my-md-0"
                  id="btn-light"
                  component="button"
                  content="promocodes"
                  onClick={this.onSideNave}
                  name="promocodes"
                />
              </div>

              {store.getState().auth.userType === "admin" ||
              localStorage.getItem("userType") === "admin" ? (
                <div className="d-flex">
                  <FontAwesomeIcon
                    className="my-auto"
                    icon={faUsers}
                    style={{ fontSize: "30px", marginRight: "20px" }}
                  />
                  <Translate
                    style={{ height: "60px", marginRight: "20px" }}
                    className="btn btn-light p-0 my-3 my-md-0"
                    id="btn-light"
                    component="button"
                    content="users"
                    onClick={this.onSideNave}
                    name="users"
                  />
                </div>
              ) : null}
            </div>
            {store.getState().auth.userType === "admin" ||
            localStorage.getItem("userType") === "admin" ? (
              <div>
                <hr className="bg-light mx-3"></hr>
                <div className="mx-auto" style={{ width: "fit-content" }}>
                  <div className="d-flex">
                    <FontAwesomeIcon
                      className="my-auto"
                      icon={faUsers}
                      style={{ fontSize: "30px", marginRight: "20px" }}
                    />
                    <Translate
                      style={{ height: "60px", marginRight: "20px" }}
                      className="btn btn-light p-0 my-3 my-md-0"
                      id="btn-light"
                      component="button"
                      content="admins"
                      onClick={this.onSideNave}
                      name="admins"
                    />
                  </div>
                  <button
                    onClick={this.onAddAdmin}
                    className="btn btn-light p-0 my-3 my-md-0"
                    style={{ fontSize: "23px" }}
                    id="btn-light"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ fontSize: "20px", marginRight: "18px" }}
                    />
                    <Translate
                      style={{ marginRight: "20px" }}
                      content="add_admin"
                    />
                  </button>{" "}
                </div>
              </div>
            ) : null}
            <hr className="bg-light mx-3"></hr>

            <Dropdown className="">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic2"
                style={{
                  background: "none",
                  border: "none",
                  color: "purple",
                  fontSize: "20px",
                }}
              >
                <Translate content="language" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="text-center">
                <Dropdown.Item
                  onClick={this.onClickLang}
                  name="english"
                  style={{ color: "purple" }}
                >
                  <Translate
                    content="english"
                    component="a"
                    onClick={this.onClickLang}
                    name="english"
                  />{" "}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={this.onClickLang}
                  name="arabic"
                  style={{ color: "purple" }}
                >
                  <Translate
                    content="arabic"
                    component="a"
                    onClick={this.onClickLang}
                    name="arabic"
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <hr className="bg-light mx-3"></hr>
            <div className="mx-auto" style={{ width: "fit-content" }}>
              <button
                onClick={this.onlogout}
                className="btn btn-light p-0 my-3 my-md-0"
                style={{ fontSize: "23px" }}
                id="btn-light"
              >
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{ fontSize: "25px", marginRight: "18px" }}
                />
                <Translate style={{ marginRight: "20px" }} content="logout" />
              </button>
            </div>
          </div>
        </div>

        {/**reports */}
        {this.state.dashboard === "reports" ? (
          <Reports />
        ) : this.state.dashboard === "events" ? (
          <Events />
        ) : this.state.dashboard === "providers" ? (
          <Providers />
        ) : this.state.dashboard === "users" ? (
          <Users />
        ) : this.state.dashboard === "admins" ? (
          <Admins />
        ) : this.state.dashboard === "promocodes" ? (
          <Promocodes />
        ) : this.state.dashboard === "newEvent" ? (
          <NewEvent />
        ) : (
          <Reports />
        )}

        <AdminModal
          adminModal={this.state.adminModal}
          handleAdminModal={this.handleAdminModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(dashboard);
