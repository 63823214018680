import { combineReducers } from 'redux'

import errorReducer from './errorReducer'
import authReducer from './authReducer'
import eventReducer from './eventReducer'
import promoReducer from './promocodeReducer'
import providersReducer from './providersReducer'
import usersReducer from './usersReducer'
import bookingReducer from './bookingreducer'


export default combineReducers({
    error: errorReducer,
    auth: authReducer,
    eventState: eventReducer,
    promoState: promoReducer,
    providerState: providersReducer,
    userState: usersReducer,
    userBooking: bookingReducer
})