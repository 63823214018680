import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import { faUserCircle,  faArrowLeft, faEdit} from '@fortawesome/free-solid-svg-icons'
import { faBan, faCheckCircle,  faTrash } from '@fortawesome/free-solid-svg-icons'
import EditEventModal from './editEventModal'
import {connect} from 'react-redux'
import {updateEvent, deleteEvent, getEvent, clearEvent} from '../actions/eventsActions'
import Translate from 'react-translate-component'
class ProviderPage extends React.Component{

    state= {
        eventActive: false,
        editEventModal: false
      }

      onDeactivate = ( id, active, e) => {
        
      
        this.props.updateEvent(id, {active: !active})

    }

    onClickDeleteEvent = (id, e) => {
       this.props.deleteEvent(id)
    }

    handleEditEventModal = () => {
        this.props.clearEvent()
        this.setState({editEventModal: false})
    }

    
    onClickEditEvent = (id, e) => {
        this.props.getEvent(id)
        
        this.setState({
            editEventModal : !this.state.editEventModal,
            
        })

   
    }


   

    render() {

     let {provider, employers, events} = this.props
     let date = Date.now()
     let today = new Date(date).getTime()

     let liveEvents = events.filter(event =>{
         
         let eventDate = new Date(event.date).getTime()
         let difference= (eventDate - today);
         let days = difference/(1000 * 3600 * 24)
         
         return days > 0
     })
     
     let oldEvents = events.filter(event =>{
         
        let eventDate = new Date(event.date).getTime()
        let difference= (eventDate - today);
        let days = difference/(1000 * 3600 * 24)
        
        return days < 0
    })

    

        

        return(
            <div  className='reports justify-content-center mx-auto text-center ' style={{ width: '90%' }}>
              
             <div>
             <button onClick={this.props.handleProvider} className='btn btn-light ' style={{color: 'purple', background:'#dee2e6'}}>
                  <FontAwesomeIcon icon = {faArrowLeft} style={{fontSize: '15px', marginRight: '10px'}}/>
                  <Translate content='back'/>
              </button>
             </div>

             <EditEventModal
                                    editEventModal = {this.state.editEventModal}
                                    handleEditEventModal = {this.handleEditEventModal}/> 

              
                <div className = 'row justify-content-center mx-auto'>
                     
                    <div className = 'card shadow mx-3 my-3' style={{width: 'fit-content',border: 'none', height: 'auto', overflow: 'auto'}}>
                        <div className =' card-body ' style={{color: 'purple'}}>
                            <FontAwesomeIcon icon ={faUserCircle} style={{fontSize: '70px'}}/>
                                 
                            <table id='providerTable' style={{width: '100%', textAlign: 'start'}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Translate content= 'provider_name'/>
                                        </td>
                                        <td>
                                            {provider.name}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                        <Translate content= 'email'/>
                                        </td>
                                        <td>
                                        {provider.email}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                        <Translate content= 'events_count'/>
                                        </td>
                                        <td>
                                        {events.length}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                        <Translate content= 'staff_count'/>
                                        </td>
                                        <td>
                                        {employers.length}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                        <Translate content= 'join_date'/>
                                        </td>
                                        <td>
                                            {provider.date}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                        <Translate content= 'phone'/>
                                        </td>
                                        <td>
                                            {provider.phone}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className = 'card shadow mx-3  my-3' style={{ width: '600px',height: 'auto', border: 'none',   background: 'linear-gradient(to top, rgb(220 36 36 / 83%), rgb(33 45 113))'}}>
                        <div className =' card-body text-center row' style ={{justifyContent: 'space-around'}}>
                            <div className = 'my-5'>
                            <h1>{events.length}</h1>
                                <h3><Translate content= 'total_events'/></h3>
                                
                            </div>

                            <div className = 'my-5 pt-0 pt-md-5'>
                            <h1>{liveEvents.length}</h1>
                                <h3><Translate content= 'live_events'/></h3>
                                
                            </div>

                            <div className = 'my-5 '>
                            <h1>{oldEvents.length}</h1>
                                <h3><Translate content= 'end_events'/></h3>
                                
                            </div>
                        </div>

                        <div className='first-circle' style={{width: '200px', height: '200px', background: '#f8f9fa26', reght: '0px', bottom: '50px'}}></div>
                    <div className='sec-circle' style={{width: '200px', height: '200px', background: '#f8f9fa17', right: '50px'}}></div>

                    </div>
                </div>

                {/** events */}

                <h5 className ='my-4' style={{color: 'purple'}}><Translate content='provider_events'/></h5>

                   
            <div className='card shadow mx-auto text-center '  style={{ maxWidth: '90%', width: 'fit-content',height : '400px' , color: 'purple', overflow : 'auto' }}>
                <table className='mt-3' >

                    <thead className='mx-auto ' style={{ borderBottom: '.5px solid #ff5e00 ' }}>
                        <tr>
                            <th ><Translate content= 'image'/></th>

                            <th ><Translate content= 'event_name'/></th>
                            <th ><Translate content= 'event_type'/></th>

                            <th ><Translate content= 'star_name'/></th>
                            <th ><Translate content= 'event_date'/></th>

                            <th ><Translate content= 'seats_count'/></th>

                      

                            <th ><Translate content= 'staff_count'/></th>

                            <th ><Translate content= 'address'/></th>

                           
                    
                        </tr>
                    </thead>

                    <tbody >
                    {events.map((event ,key = 0 ) => (
                            <tr key = {key ++} style={{borderBottom: '1px solid #ffa50040'}}>
                            <td >
                                <img alt = 'img'  className =' my-3' src={event.imageUrl} width='100' height= '70'
                                style={{borderRadius: '18px'}}/>
                            </td>
                        
                            <td >{event.name}</td>
                            <td >{event.category}</td>
                            <td >{event.starName}</td>
                            <td style={{width: '150px'}}>{event.date}</td>
                            <td >{event.numberOfAvailableSeats}</td>
                   
                            <td >{event.bookedSeats || 0}</td>
                            <td className = 'py-2'>{event.address}</td>   

                            <td > <button onClick = {this.onClickEditEvent.bind(this, event._id)} id='btn-light'><FontAwesomeIcon icon = {faEdit}/></button></td>
                            <td > <button onClick = {this.onDeactivate.bind(this, event._id, event.active)} id='btn-light'><FontAwesomeIcon icon = {event.active ? faCheckCircle : faBan}/></button></td>

                            <td > <button onClick = {this.onClickDeleteEvent.bind(this, event._id)}  id='btn-light'><FontAwesomeIcon icon = {faTrash}/></button></td>
                        </tr>
                        
                      ))}
                    </tbody>



                </table>

            </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    provider : state.providerState.providerData.provider,
    employers: state.providerState.providerData.employers,
    events: state.eventState.providerEvents
})

export default connect(mapState, {updateEvent, deleteEvent, getEvent, clearEvent})(ProviderPage)